<template>
  <v-overlay v-if="loading" :model-value="true" :persistent="true" class="align-center justify-center">
    <v-progress-circular indeterminate size="64" class="color-theme"></v-progress-circular>
  </v-overlay>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useLoading } from "@/composables";

export default defineComponent({
  name: "AppLoading",

  setup() {
    const { loading } = useLoading();
    return {
      loading,
    };
  },
});
</script>
