export default {
  state: {
    module: {
      default: {},
      sim: {},
      member: {},
      ota: {},
    },
  },
  getters: {},
  mutations: {
    SET_MODULE_FILTER: (state: any, { module, attrs }: any) => (state.module[module] = attrs),
  },
  actions: {
    setModuleFilter: (context: any, payload: any) => context.commit("SET_MODULE_FILTER", payload),
  },
};
