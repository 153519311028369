type Paginate = {
  limit: number;
  totalPage: number;
  totalItem: number;
  currentPage: number;
};

export type State = {
  module: Record<string, Paginate>;
};

export const defaultPaginate: Paginate = {
  limit: 20,
  totalPage: 0,
  totalItem: 0,
  currentPage: 1,
};

export default {
  state: {
    module: {
      default: structuredClone(defaultPaginate),
      sim: structuredClone(defaultPaginate),
      ota: structuredClone(defaultPaginate),
      otaLog: structuredClone(defaultPaginate),
      member: structuredClone(defaultPaginate),
      systemLog: { ...structuredClone(defaultPaginate), limit: 100 },
    },
  },
  getters: {},
  mutations: {
    SET_MODULE_PAGINATE: (state: State, payload: { module: string; paginate: Paginate }) => (state.module[payload.module] = payload.paginate),
    SET_MODULE_LIMIT: (state: State, { module, limit }: { module: string; limit: number }) => (state.module[module].limit = limit),
    SET_MODULE_CURRENT_PAGE: (state: State, { module, currentPage }: { module: string; currentPage: number }) =>
      (state.module[module].currentPage = currentPage),
    SET_MODULE_TOTAL_PAGE: (state: State, { module, totalPage }: { module: string; totalPage: number }) => (state.module[module].totalPage = totalPage),
    SET_MODULE_TOTAL_ITEM: (state: State, { module, totalItem }: { module: string; totalItem: number }) => (state.module[module].totalItem = totalItem),
  },
  actions: {
    initModulePaginate: (context: any, module: string) => context.commit("SET_MODULE_PAGINATE", { module, paginate: structuredClone(defaultPaginate) }),
    setModuleLimit: (context: any, payload: { module: string; limit: number }) => context.commit("SET_MODULE_LIMIT", payload),
    setModuleCurrentPage: (context: any, payload: { module: string; currentPage: number }) => context.commit("SET_MODULE_CURRENT_PAGE", payload),
    setModuleTotalPage: (context: any, payload: { module: string; totalPage: number }) => context.commit("SET_MODULE_TOTAL_PAGE", payload),
    setModuleTotalItem: (context: any, payload: { module: string; totalItem: number }) => context.commit("SET_MODULE_TOTAL_ITEM", payload),
  },
};
