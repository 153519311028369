import { saveAs } from "file-saver";
import useConfig from "./useConfig";
import useDateFormat from "./useDateFormat";

export default function useExport() {
  const { EXPORT_EXTENSION } = useConfig();
  const { getUnixNow } = useDateFormat();

  const createExportFile = async (data: Blob, file_name: string, opts?: any) => {
    saveAs(data, file_name, opts);
  };

  const createExportLink = async (blob: Blob, file_name: string) => {
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);

    link.download = file_name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const getExportName = (module: string) => `${module}-${getUnixNow()}.${EXPORT_EXTENSION}`;

  return {
    createExportFile,
    getExportName,
    createExportLink,
  };
}
