import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default function useFilter(currentModule: string) {
  const isInteger = (num: any) => /^-?\d+$/.test(num);
  const toInteger = (num: any) => parseInt(num, 10);
  const MAX_INT = Number.MAX_SAFE_INTEGER;

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const module = computed(() => currentModule || "default");
  const query = computed(() => route.query as any);

  const moduleAttrs = computed(() => store.state.filter.module[module.value] || {});

  const prepareAttrs = (data: Record<string, any>) => {
    return Object.entries(data).reduce((queries, [key, value]) => {
      if (isInteger(value)) {
        const v = ["connection", "role_id", "status"].includes(key as any) ? toInteger(value) : value;
        if (v <= MAX_INT) {
          queries[key] = v;
          return queries;
        }
      }
      if (![null, undefined, ""].includes(value as any)) {
        queries[key] = value;
        return queries;
      }
      return queries;
    }, {} as Record<string, any>);
  };

  const queryAttrs = computed(() => {
    const attrs = prepareAttrs(query.value);
    return attrs;
  });

  const filterAttrs = computed(() => prepareAttrs(Object.assign({ ...moduleAttrs.value }, { ...queryAttrs.value })));

  const setFilter = (attrs: any) => {
    store.dispatch("setModuleFilter", { module: module.value, attrs: attrs });
  };

  const setFilterQuery = async (attrs: any, routeParams?: { name: string; params: Record<string, any> }, enableHistory = true) => {
    const _attrs = prepareAttrs(attrs);
    await store.dispatch("setModuleFilter", { module: module.value, attrs: _attrs });
    const params = {
      query: _attrs,
      name: routeParams?.name,
      params: routeParams?.params,
    };
    if (enableHistory) {
      await router.push(params);
    } else {
      await router.replace(params);
    }
  };

  return {
    filterAttrs,
    queryAttrs,
    moduleAttrs,
    query,
    setFilter,
    setFilterQuery,
  };
}
