<template>
  <page-template>
    <form-template>
      <template #title>サーバーが混み合っています</template>
      <p>
        現在このページを表示することができません。<br />
        しばらくたってからもう一度お試しください。
      </p>
      <div class="mt-5">
        <v-btn class="w-100" color="primary" @click="reload">再読み込み</v-btn>
      </div>
    </form-template>
  </page-template>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import PageTemplate from "@/components/Authentication/PageTemplate.vue";
import FormTemplate from "@/components/Authentication/FormTemplate.vue";

export default defineComponent({
  name: "BusyPage",
  components: {
    PageTemplate,
    FormTemplate,
  },

  setup() {
    const reload = () => {
      location.reload();
    };

    return {
      reload,
    };
  },
});
</script>
