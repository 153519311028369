import request from "@/services/request";

export const getUserSettings = (params: any): Promise<any> => {
  return request.get("/v1/user-config", { params });
};

export const createOrUpdateUserSettings = (params: any[]): Promise<any> => {
  return request.post("/v1/user-config", params);
};

export const createOrUpdateLanguageSetting = (params: any[]): Promise<any> => {
  return request.post("/v1/language", params);
};
