import { useI18n } from "vue-i18n";

export default function useRSRP() {
  const { t } = useI18n();
  const RSRP_CRITERIA = {
    EXTREMELY_STRONG: -44,
    VERY_STRONG: -90,
    STRONG: -100,
    VERY_WEAK: -120,
  };

  const getText = (rsrp: number): string => {
    if (typeof rsrp !== "number") {
      return "";
    }
    if (rsrp >= RSRP_CRITERIA.EXTREMELY_STRONG) {
      return t("sim_rsrp.extremely_strong");
    } else if (rsrp >= RSRP_CRITERIA.VERY_STRONG) {
      return t("sim_rsrp.very_strong");
    } else if (rsrp >= RSRP_CRITERIA.STRONG) {
      return t("sim_rsrp.strong");
    } else if (rsrp >= RSRP_CRITERIA.VERY_WEAK) {
      return t("sim_rsrp.very_weak");
    } else {
      return t("sim_rsrp.unusable");
    }
  };

  const isUnusable = (rsrp: number): boolean => {
    if (typeof rsrp !== "number") {
      return false;
    }
    return rsrp < RSRP_CRITERIA.VERY_WEAK;
  };

  return {
    getText,
    isUnusable,
  };
}
