import { countries, operators, months, hours12, hours, minutes, hourPeriods, days, scheduleTypes, weekDays } from "@/services";

export default {
  state: {
    countries,
    operators,
    months,
    hours,
    hours12,
    minutes,
    hourPeriods,
    days,
    scheduleTypes,
    weekDays,
  },
  getters: {},
  mutations: {},
  actions: {},
};
