import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    filter: {
      id: undefined,
      type: undefined,
      external_device_id: undefined,
      operator: undefined,
      cm_platform: undefined,
      modified_start: undefined,
      modified_end: undefined,
    },
    items: [] as any[],
    updated_index: -1,
    sim_detail: undefined,
    auto_generated_iccid: undefined,
  },
  getters: {
    getItems: (state: any) => state.items || [],
    getUpdatedIndex: (state: any) => state.updated_index,
  },
  mutations: {
    APPLY_FILTER: (state: any, payload: any) => (state.filter = payload),
    SET_ITEMS: (state: any, payload: any) => (state.items = payload),
    SET_SIM_DETAIL: (state: any, payload: any) => (state.sim_detail = payload),
    SET_UPDATED_INDEX: (state: any, payload: any) => (state.updated_index = payload),
    SET_AUTO_GENERATED_ICCID: (state: any, payload: string) => (state.auto_generated_iccid = payload),
  },
  actions: {
    applyFilter: ({ commit }: ActionContext<any, any>, payload: any) => commit("APPLY_FILTER", payload),
    updateByWebSocket: ({ state, commit, dispatch }: ActionContext<any, any>, payload: any) => {
      const targetIndex = state.items.findIndex((item: any) => item.id === payload.sim.id);

      commit("SET_UPDATED_INDEX", targetIndex);

      const nextItems = [...state.items];
      if (targetIndex !== -1) {
        nextItems[targetIndex] = {
          ...nextItems[targetIndex],
          ...payload.sim,
        };
        commit("SET_ITEMS", nextItems);
      } else if (payload.sim.created_at === payload.sim.updated_at) {
        // NOTE: スマホ等端末側からのアクセスによりSIMのデータが自動生成された場合にこの処理が走る
        commit("SET_AUTO_GENERATED_ICCID", payload.sim.iccid);
      }
    },
    setSimDetail: ({ commit }: ActionContext<any, any>, payload: any) => commit("SET_SIM_DETAIL", payload),
    refreshItems: ({ state, commit }: ActionContext<any, any>, payload: any) => {
      commit("SET_ITEMS", [...state.items]);
    },
    cleaUpdatedIndex: ({ commit }: ActionContext<any, any>, payload: any) => {
      commit("SET_UPDATED_INDEX", -1);
    },
    resetAutoGeneratedIccid: ({ commit }: ActionContext<any, any>) => {
      commit("SET_AUTO_GENERATED_ICCID", undefined);
    },
  },
};
