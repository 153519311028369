export default {
  state: {
    filter: {
      name: undefined,
      email: undefined,
      role_id: undefined,
      status: undefined,
      created_start: undefined,
      created_end: undefined,
    },
    members: [],
  },
  getters: {},
  mutations: {
    APPLY_FILTER: (state: any, payload: any) => (state.filter = payload),
    SET_MEMBERS: (state: any, payload: any) => (state.members = payload),
  },
  actions: {
    applyFilter: (context: any, payload: any) => context.commit("APPLY_FILTER", payload),
    setMembers: (context: any, payload: any) => context.commit("SET_MEMBERS", payload),
  },
};
