<template>
  <form>
    <div class="mt-4 pa-6 login-form">
      <p class="login-text primary--text mb-6">
        <slot name="title">default title</slot>
      </p>
      <slot>default slot</slot>
      <div class="mt-6 text-white text-center text-copyright" style="font-size: 12px">
        <copyright color="black" />
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Copyright from "@/components/BaseComponent/Copyright.vue";

export default defineComponent({
  name: "AuthFormTemplate",
  components: {
    Copyright,
  },
});
</script>

<style scoped>
.login-text {
  font-size: 24px;
  font-weight: 500;
}
.login-form {
  border-radius: 4px;
  background: white;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.12);
  width: 448px;
}
</style>
