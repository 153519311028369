export default {
  state: {
    module: {
      sim: undefined,
      ota: undefined,
      member: undefined,
      systemMail: undefined,
    },
  },
  getters: {},
  mutations: {
    SET_MODULE_SORT: (state: any, { module, sort }: any) => (state.module[module] = sort),
  },
  actions: {
    setModuleSort: (context: any, payload: any) => context.commit("SET_MODULE_SORT", payload),
  },
};
