export default function useImgBase64() {
  const toBase64 = async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (err: any) => {
        reject(err);
      };
    });
  };

  return {
    toBase64,
  };
}
