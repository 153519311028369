import { useToast, ToastProps } from "vue-toast-notification";
import { useStore } from "vuex";
import { computed } from "vue";
import { NotificationType } from "../store/notification";

export default function useNotification() {
  const toast = useToast();
  const duration = parseInt(process.env.TOASTR_DURATION!, 10);
  const defaultOptions: ToastProps = {
    position: "top-right",
    duration: !isNaN(duration) ? duration : 5000,
    queue: true,
  };

  const showMessage = (message: string, options: ToastProps = {}) => toast.default(message, { ...defaultOptions, ...options });
  const showSuccess = (message: string, options: ToastProps = {}) => toast.success(message, { ...defaultOptions, ...options });
  const showError = (message: string, options: ToastProps = {}) => toast.error(message, { ...defaultOptions, ...options });
  const showWarning = (message: string, options: ToastProps = {}) => toast.warning(message, { ...defaultOptions, ...options });
  const showInfo = (message: string, options: ToastProps = {}) => toast.info(message, { ...defaultOptions, ...options });

  const store = useStore();
  const notificationCount = computed(() => store.getters["notification/getAllCount"]);
  const simNotificationItems = computed(() => store.getters["notification/simNotificationItems"]);
  const simNotificationCount = computed(() => store.getters["notification/simNotificationCount"]);
  const clearSimNotificationCount = () => store.dispatch("notification/clearSimCount");
  const otaNotificationItems = computed(() => store.getters["notification/otaNotificationItems"]);
  const otaNotificationCount = computed(() => store.getters["notification/otaNotificationCount"]);
  const clearOTANotificationCount = () => store.dispatch("notification/clearOTACount");
  const clearNotificationCount = () => {
    store.dispatch("notification/clearAllCount");
  };

  return {
    toast,
    showMessage,
    showSuccess,
    showError,
    showWarning,
    showInfo,
    simNotificationItems,
    otaNotificationItems,
    notificationCount,
    simNotificationCount,
    otaNotificationCount,
    clearSimNotificationCount,
    clearOTANotificationCount,
    clearNotificationCount,
  };
}
