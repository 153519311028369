import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import i18n from "./plugins/i18n";
import createRouteBeforeEach from "@/router/auth";
import VueToast from "@/plugins/toastr";

loadFonts();

//middleware for auth
router.beforeEach((to, from, next) => createRouteBeforeEach(to, from, next));
const app = createApp(App).use(i18n).use(router).use(store).use(vuetify).use(VueToast);
app.mount("#app");

// NOTE: Vueコンポーネントのマウント状態に関わらず、WebSocketのKeepAliveを開始する
//       WebSocketが切断されている場合は、setIntervalで呼び出したsendKeepAliveが早期リターンするので考慮不要
store.dispatch("websocket/startKeepAlive");
