import { ref, computed } from "vue";
import { useStore } from "vuex";
import { State } from "@/store/paginate";

export default function usePageLimit(currentModule = "default") {
  const store = useStore();
  const state = computed<State>(() => store.state.paginate);
  const module = computed(() => currentModule || "default");
  if (!state.value[module.value] || typeof state.value[module.value] === "number") {
    store.dispatch("initModulePaginate", module.value);
  }
  const take = computed(() => state.value.module[module.value].limit || 20);
  const takes = ref<number[]>([5, 10, 15, 20, 25, 50, 100]);
  const totalPage = ref<number>(0);
  const totalItem = ref<number>(0);
  const items = ref<any[]>([]);
  const currentPage = ref<number>(1);
  const skip = computed(() => (currentPage.value - 1) * take.value);

  const setCurrentPage = (page: number) => (currentPage.value = page);
  const setTotalPage = (page: number) => (totalPage.value = page);
  const setTotalItem = (page: number) => (totalItem.value = page);
  const setItems = (list: any[]) => (items.value = list);
  const setLimit = (limit: number) => store.dispatch("setModuleLimit", { module: module.value, limit: limit });

  return {
    items,
    takes,
    take,
    skip,
    totalPage,
    totalItem,
    currentPage,
    setCurrentPage,
    setTotalPage,
    setTotalItem,
    setItems,
    setLimit,
  };
}
