export default {
  state: {
    filter: {
      title: undefined,
      trigger: undefined,
      conditions: undefined,
      action: undefined,
      add_filter: undefined,
      created_start: undefined,
      created_end: undefined,
      status: undefined,
    },
    automations: [],
  },
  getters: {},
  mutations: {
    APPLY_FILTER: (state: any, payload: any) => (state.filter = payload),
  },
  actions: {
    applyFilter: (context: any, payload: any) => context.commit("APPLY_FILTER", payload),
  },
};
