export default {
  state: {
    user: undefined,
    accessToken: undefined,
    idToken: undefined,
    permissions: undefined,
    settings: undefined,
    selectedTheme: undefined,
    language: undefined,
  },
  getters: {
    getToken: (state: any) => state.accessToken || undefined,
    getUser: (state: any) => state.user || undefined,
    getUserId: (state: any) => state.user?.id || undefined,
    getUserRoleId: (state: any) => state.user?.role_id || 0,
    getUserMfa: (state: any) => state.user?.mfaEnabled || false,
    isAuthenticated: (state: any) => (state.accessToken ? true : false),
    getPermission: (state: any) => state.permissions || [],
    getUserSettings: (state: any) => state.settings || undefined,
    getSelectedTheme: (state: any) => state.selectedTheme || undefined,
    getLanguage: (state: any) => state.language || undefined,
  },
  mutations: {
    SET_MFA_STATUS: (state: any, payload: boolean) => (state.user.mfaEnabled = payload),
    SET_TOKEN: (state: any, payload: string) => (state.accessToken = payload),
    SET_ID_TOKEN: (state: any, payload: string) => (state.idToken = payload),
    SET_USER: (state: any, payload: any) => (state.user = payload),
    SET_AUTH: (state: any, payload: any) => {
      state.accessToken = payload.accessToken || undefined;
      state.idToken = payload.idToken || undefined;
      state.user = payload.user || undefined;
    },
    SET_LOGOUT: (state: any) => {
      state.user = null;
      state.accessToken = null;
      state.idToken = undefined;
      state.settings = undefined;
      state.selectedTheme = undefined;
    },
    SET_PERMISSION: (state: any, payload: any) => (state.permissions = payload),
    SET_USER_SETTINGS: (state: any, payload: any) => (state.settings = payload),
    SET_SELECTED_THEME: (state: any, payload: any) => (state.selectedTheme = payload),
    SET_LANGUAGE: (state: any, payload: any) => (state.language = payload),
  },
  actions: {
    setMfaStatus: (context: any, payload: boolean) => context.commit("SET_MFA_STATUS", payload),
    setToken: (context: any, payload: string) => context.commit("SET_TOKEN", payload),
    setIdToken: (context: any, payload: string) => context.commit("SET_ID_TOKEN", payload),
    setUser: (context: any, payload: any) => context.commit("SET_USER", payload),
    setAuth: (context: any, payload: any) => context.commit("SET_AUTH", payload),
    logout: (context: any) => context.commit("SET_LOGOUT"),
    setPermission: (context: any, payload: any) => context.commit("SET_PERMISSION", payload),
    setUserSettings: (context: any, payload: any) => context.commit("SET_USER_SETTINGS", payload),
    setSelectedTheme: (context: any, payload: string) => context.commit("SET_SELECTED_THEME", payload),
    setLanguage: (context: any, payload: any) => context.commit("SET_LANGUAGE", payload),
  },
};
