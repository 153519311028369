<template>
  <span class="svg-icon">
    <svg v-if="icon === 'sim'" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6583 2.33366C13.6583 1.41699 12.9167 0.666992 12 0.666992H5.33334L0.333344 5.66699V15.667C0.333344 16.5837 1.08334 17.3337 2.00001 17.3337H12.0083C12.925 17.3337 13.6667 16.5837 13.6667 15.667L13.6583 2.33366ZM4.50001 14.8337H2.83334V13.167H4.50001V14.8337ZM11.1667 14.8337H9.50001V13.167H11.1667V14.8337ZM4.50001 11.5003H2.83334V8.16699H4.50001V11.5003ZM7.83334 14.8337H6.16668V11.5003H7.83334V14.8337ZM7.83334 9.83366H6.16668V8.16699H7.83334V9.83366ZM11.1667 11.5003H9.50001V8.16699H11.1667V11.5003Z"
        fill="white"
      />
    </svg>
    <svg v-if="icon === 'ota'" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.833252 4.4998L2.49992 6.16647C6.64158 2.02481 13.3583 2.02481 17.4999 6.16647L19.1666 4.4998C14.1083 -0.558529 5.89992 -0.558529 0.833252 4.4998ZM7.49992 11.1665L9.99992 13.6665L12.4999 11.1665C11.1249 9.78314 8.88325 9.78314 7.49992 11.1665ZM4.16658 7.83314L5.83325 9.4998C8.13325 7.1998 11.8666 7.1998 14.1666 9.4998L15.8333 7.83314C12.6166 4.61647 7.39158 4.61647 4.16658 7.83314Z"
        fill="white"
      />
    </svg>
    <svg v-if="icon === 'group'" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99999 3.83333V0.5H0.666656V15.5H17.3333V3.83333H8.99999ZM3.99999 13.8333H2.33332V12.1667H3.99999V13.8333ZM3.99999 10.5H2.33332V8.83333H3.99999V10.5ZM3.99999 7.16667H2.33332V5.5H3.99999V7.16667ZM3.99999 3.83333H2.33332V2.16667H3.99999V3.83333ZM7.33332 13.8333H5.66666V12.1667H7.33332V13.8333ZM7.33332 10.5H5.66666V8.83333H7.33332V10.5ZM7.33332 7.16667H5.66666V5.5H7.33332V7.16667ZM7.33332 3.83333H5.66666V2.16667H7.33332V3.83333ZM15.6667 13.8333H8.99999V12.1667H10.6667V10.5H8.99999V8.83333H10.6667V7.16667H8.99999V5.5H15.6667V13.8333ZM14 7.16667H12.3333V8.83333H14V7.16667ZM14 10.5H12.3333V12.1667H14V10.5Z"
        fill="white"
      />
    </svg>
    <svg v-if="icon === 'member'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00001 6.99967C8.84168 6.99967 10.3333 5.50801 10.3333 3.66634C10.3333 1.82467 8.84168 0.333008 7.00001 0.333008C5.15834 0.333008 3.66668 1.82467 3.66668 3.66634C3.66668 5.50801 5.15834 6.99967 7.00001 6.99967ZM7.00001 8.66634C4.77501 8.66634 0.333344 9.78301 0.333344 11.9997V13.6663H13.6667V11.9997C13.6667 9.78301 9.22501 8.66634 7.00001 8.66634Z"
        fill="white"
      />
    </svg>
    <svg v-if="icon === 'cpms'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00002 0.666992C4.40002 0.666992 0.666687 4.40033 0.666687 9.00033C0.666687 13.6003 4.40002 17.3337 9.00002 17.3337C13.6 17.3337 17.3334 13.6003 17.3334 9.00033C17.3334 4.40033 13.6 0.666992 9.00002 0.666992ZM6.05002 11.042L4.91669 10.5253C5.15002 10.017 5.25835 9.49199 5.25002 8.97533C5.24169 8.45033 5.13335 7.94199 4.91669 7.47533L6.05002 6.95033C6.34169 7.57533 6.49169 8.25033 6.50002 8.95033C6.50835 9.66699 6.35835 10.367 6.05002 11.042ZM8.60835 12.342L7.52502 11.7253C7.95835 10.9587 8.17502 10.0753 8.17502 9.10033C8.17502 8.10866 7.95002 7.15866 7.50835 6.26699L8.62502 5.70866C9.15835 6.77533 9.42502 7.91699 9.42502 9.10033C9.42502 10.292 9.15002 11.3837 8.60835 12.342ZM11.225 13.442L10.1 12.892C10.75 11.5587 11.0834 10.242 11.0834 8.98366C11.0834 7.72533 10.75 6.42533 10.1 5.11699L11.2167 4.55866C11.9667 6.04199 12.3334 7.52533 12.3334 8.98366C12.3334 10.4337 11.9667 11.9337 11.225 13.442Z"
        fill="white"
      />
    </svg>
    <svg v-if="icon === 'notification'" width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99998 16.333C7.91665 16.333 8.66665 15.583 8.66665 14.6663H5.33331C5.33331 15.583 6.07498 16.333 6.99998 16.333ZM12 11.333V7.16634C12 4.60801 10.6333 2.46634 8.24998 1.89967V1.33301C8.24998 0.641341 7.69165 0.0830078 6.99998 0.0830078C6.30831 0.0830078 5.74998 0.641341 5.74998 1.33301V1.89967C3.35831 2.46634 1.99998 4.59967 1.99998 7.16634V11.333L0.333313 12.9997V13.833H13.6666V12.9997L12 11.333Z"
        fill="white"
      />
    </svg>
    <svg v-if="icon === 'sms'" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1667 0.666992H1.83335C0.916687 0.666992 0.17502 1.41699 0.17502 2.33366L0.166687 17.3337L3.50002 14.0003H15.1667C16.0834 14.0003 16.8334 13.2503 16.8334 12.3337V2.33366C16.8334 1.41699 16.0834 0.666992 15.1667 0.666992ZM6.00002 8.16699H4.33335V6.50033H6.00002V8.16699ZM9.33335 8.16699H7.66669V6.50033H9.33335V8.16699ZM12.6667 8.16699H11V6.50033H12.6667V8.16699Z"
        fill="white"
      />
    </svg>
    <svg v-if="icon === 'statistics'" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.166687 3.66699H2.66669V11.8337H0.166687V3.66699ZM4.83335 0.166992H7.16669V11.8337H4.83335V0.166992ZM9.50002 6.83366H11.8334V11.8337H9.50002V6.83366Z"
        fill="white"
      />
    </svg>
    <svg v-if="icon === 'settings'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.95 8.78333C13.9834 8.53333 14.0001 8.275 14.0001 8C14.0001 7.73333 13.9834 7.46667 13.9417 7.21667L15.6334 5.9C15.7834 5.78333 15.8251 5.55833 15.7334 5.39167L14.1334 2.625C14.0334 2.44167 13.8251 2.38333 13.6417 2.44167L11.65 3.24167C11.2334 2.925 10.7917 2.65833 10.3 2.45833L10 0.341667C9.96672 0.141667 9.80005 0 9.60005 0H6.40005C6.20005 0 6.04172 0.141667 6.00838 0.341667L5.70838 2.45833C5.21672 2.65833 4.76672 2.93333 4.35838 3.24167L2.36672 2.44167C2.18338 2.375 1.97505 2.44167 1.87505 2.625L0.283384 5.39167C0.183384 5.56667 0.216717 5.78333 0.383384 5.9L2.07505 7.21667C2.03338 7.46667 2.00005 7.74167 2.00005 8C2.00005 8.25833 2.01672 8.53333 2.05838 8.78333L0.366717 10.1C0.216717 10.2167 0.175051 10.4417 0.266717 10.6083L1.86672 13.375C1.96672 13.5583 2.17505 13.6167 2.35838 13.5583L4.35005 12.7583C4.76672 13.075 5.20838 13.3417 5.70005 13.5417L6.00005 15.6583C6.04172 15.8583 6.20005 16 6.40005 16H9.60005C9.80005 16 9.96672 15.8583 9.99172 15.6583L10.2917 13.5417C10.7834 13.3417 11.2334 13.075 11.6417 12.7583L13.6334 13.5583C13.8167 13.625 14.0251 13.5583 14.1251 13.375L15.725 10.6083C15.8251 10.425 15.7834 10.2167 15.625 10.1L13.95 8.78333ZM8.00005 11C6.35005 11 5.00005 9.65 5.00005 8C5.00005 6.35 6.35005 5 8.00005 5C9.65005 5 11 6.35 11 8C11 9.65 9.65005 11 8.00005 11Z"
        fill="white"
      />
    </svg>
    <svg v-if="icon === 'logs'" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12,3C7.58,3 4,4.79 4,7C4,9.21 7.58,11 12,11C16.42,11 20,9.21 20,7C20,4.79 16.42,3 12,3M4,9V12C4,14.21 7.58,16 12,16C16.42,16 20,14.21 20,12V9C20,11.21 16.42,13 12,13C7.58,13 4,11.21 4,9M4,14V17C4,19.21 7.58,21 12,21C16.42,21 20,19.21 20,17V14C20,16.21 16.42,18 12,18C7.58,18 4,16.21 4,14Z"
        fill="white"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'settings-blue'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.76 7.62628C11.7867 7.42627 11.8 7.21961 11.8 6.99961C11.8 6.78628 11.7867 6.57294 11.7533 6.37294L13.1067 5.31961C13.2267 5.22628 13.26 5.04628 13.1867 4.91294L11.9067 2.69961C11.8267 2.55294 11.66 2.50628 11.5133 2.55294L9.92 3.19294C9.58667 2.93961 9.23333 2.72628 8.84 2.56628L8.6 0.872943C8.57333 0.712943 8.44 0.599609 8.28 0.599609H5.72C5.56 0.599609 5.43333 0.712943 5.40667 0.872943L5.16667 2.56628C4.77333 2.72628 4.41333 2.94628 4.08667 3.19294L2.49333 2.55294C2.34667 2.49961 2.18 2.55294 2.1 2.69961L0.826666 4.91294C0.746666 5.05294 0.773333 5.22628 0.906666 5.31961L2.26 6.37294C2.22667 6.57294 2.2 6.79294 2.2 6.99961C2.2 7.20628 2.21333 7.42627 2.24667 7.62628L0.893333 8.67961C0.773333 8.77294 0.739999 8.95294 0.813333 9.08628L2.09333 11.2996C2.17333 11.4463 2.34 11.4929 2.48667 11.4463L4.08 10.8063C4.41333 11.0596 4.76667 11.2729 5.16 11.4329L5.4 13.1263C5.43333 13.2863 5.56 13.3996 5.72 13.3996H8.28C8.44 13.3996 8.57333 13.2863 8.59333 13.1263L8.83333 11.4329C9.22667 11.2729 9.58667 11.0596 9.91333 10.8063L11.5067 11.4463C11.6533 11.4996 11.82 11.4463 11.9 11.2996L13.18 9.08628C13.26 8.93961 13.2267 8.77294 13.1 8.67961L11.76 7.62628ZM7 9.39961C5.68 9.39961 4.6 8.31961 4.6 6.99961C4.6 5.67961 5.68 4.59961 7 4.59961C8.32 4.59961 9.4 5.67961 9.4 6.99961C9.4 8.31961 8.32 9.39961 7 9.39961Z"
        fill="#0E367D"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'sim-blue'" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6583 2.33366C13.6583 1.41699 12.9167 0.666992 12 0.666992H5.33334L0.333344 5.66699V15.667C0.333344 16.5837 1.08334 17.3337 2.00001 17.3337H12.0083C12.925 17.3337 13.6667 16.5837 13.6667 15.667L13.6583 2.33366ZM4.50001 14.8337H2.83334V13.167H4.50001V14.8337ZM11.1667 14.8337H9.50001V13.167H11.1667V14.8337ZM4.50001 11.5003H2.83334V8.16699H4.50001V11.5003ZM7.83334 14.8337H6.16668V11.5003H7.83334V14.8337ZM7.83334 9.83366H6.16668V8.16699H7.83334V9.83366ZM11.1667 11.5003H9.50001V8.16699H11.1667V11.5003Z"
        fill="#0E367D"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'ota-blue'" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.666748 3.99984L2.00008 5.33318C5.31341 2.01984 10.6867 2.01984 14.0001 5.33318L15.3334 3.99984C11.2867 -0.0468228 4.72008 -0.0468228 0.666748 3.99984ZM6.00008 9.33318L8.00008 11.3332L10.0001 9.33318C8.90008 8.22651 7.10675 8.22651 6.00008 9.33318ZM3.33341 6.66651L4.66675 7.99984C6.50675 6.15984 9.49341 6.15984 11.3334 7.99984L12.6667 6.66651C10.0934 4.09318 5.91341 4.09318 3.33341 6.66651Z"
        fill="#0E367D"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'group-blue'" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99999 3.83333V0.5H0.666656V15.5H17.3333V3.83333H8.99999ZM3.99999 13.8333H2.33332V12.1667H3.99999V13.8333ZM3.99999 10.5H2.33332V8.83333H3.99999V10.5ZM3.99999 7.16667H2.33332V5.5H3.99999V7.16667ZM3.99999 3.83333H2.33332V2.16667H3.99999V3.83333ZM7.33332 13.8333H5.66666V12.1667H7.33332V13.8333ZM7.33332 10.5H5.66666V8.83333H7.33332V10.5ZM7.33332 7.16667H5.66666V5.5H7.33332V7.16667ZM7.33332 3.83333H5.66666V2.16667H7.33332V3.83333ZM15.6667 13.8333H8.99999V12.1667H10.6667V10.5H8.99999V8.83333H10.6667V7.16667H8.99999V5.5H15.6667V13.8333ZM14 7.16667H12.3333V8.83333H14V7.16667ZM14 10.5H12.3333V12.1667H14V10.5Z"
        fill="#0E367D"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'member-blue'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00001 6.99967C8.84168 6.99967 10.3333 5.50801 10.3333 3.66634C10.3333 1.82467 8.84168 0.333008 7.00001 0.333008C5.15834 0.333008 3.66668 1.82467 3.66668 3.66634C3.66668 5.50801 5.15834 6.99967 7.00001 6.99967ZM7.00001 8.66634C4.77501 8.66634 0.333344 9.78301 0.333344 11.9997V13.6663H13.6667V11.9997C13.6667 9.78301 9.22501 8.66634 7.00001 8.66634Z"
        fill="#0E367D"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'cpms-blue'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00002 0.666992C4.40002 0.666992 0.666687 4.40033 0.666687 9.00033C0.666687 13.6003 4.40002 17.3337 9.00002 17.3337C13.6 17.3337 17.3334 13.6003 17.3334 9.00033C17.3334 4.40033 13.6 0.666992 9.00002 0.666992ZM6.05002 11.042L4.91669 10.5253C5.15002 10.017 5.25835 9.49199 5.25002 8.97533C5.24169 8.45033 5.13335 7.94199 4.91669 7.47533L6.05002 6.95033C6.34169 7.57533 6.49169 8.25033 6.50002 8.95033C6.50835 9.66699 6.35835 10.367 6.05002 11.042ZM8.60835 12.342L7.52502 11.7253C7.95835 10.9587 8.17502 10.0753 8.17502 9.10033C8.17502 8.10866 7.95002 7.15866 7.50835 6.26699L8.62502 5.70866C9.15835 6.77533 9.42502 7.91699 9.42502 9.10033C9.42502 10.292 9.15002 11.3837 8.60835 12.342ZM11.225 13.442L10.1 12.892C10.75 11.5587 11.0834 10.242 11.0834 8.98366C11.0834 7.72533 10.75 6.42533 10.1 5.11699L11.2167 4.55866C11.9667 6.04199 12.3334 7.52533 12.3334 8.98366C12.3334 10.4337 11.9667 11.9337 11.225 13.442Z"
        fill="#0E367D"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'notification-blue'" width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99998 16.333C7.91665 16.333 8.66665 15.583 8.66665 14.6663H5.33331C5.33331 15.583 6.07498 16.333 6.99998 16.333ZM12 11.333V7.16634C12 4.60801 10.6333 2.46634 8.24998 1.89967V1.33301C8.24998 0.641341 7.69165 0.0830078 6.99998 0.0830078C6.30831 0.0830078 5.74998 0.641341 5.74998 1.33301V1.89967C3.35831 2.46634 1.99998 4.59967 1.99998 7.16634V11.333L0.333313 12.9997V13.833H13.6666V12.9997L12 11.333Z"
        fill="#0E367D"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'sms-blue'" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1667 0.666992H1.83335C0.916687 0.666992 0.17502 1.41699 0.17502 2.33366L0.166687 17.3337L3.50002 14.0003H15.1667C16.0834 14.0003 16.8334 13.2503 16.8334 12.3337V2.33366C16.8334 1.41699 16.0834 0.666992 15.1667 0.666992ZM6.00002 8.16699H4.33335V6.50033H6.00002V8.16699ZM9.33335 8.16699H7.66669V6.50033H9.33335V8.16699ZM12.6667 8.16699H11V6.50033H12.6667V8.16699Z"
        fill="#0E367D"
      />
    </svg>

    <svg class="app--theme-color" v-if="icon === 'car-blue'" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1048 1.84858C18.8633 1.14158 18.1972 0.666748 17.4505 0.666748H4.5495C3.80283 0.666748 3.13667 1.14158 2.89517 1.84858L0.5 8.83341V18.1667C0.5 18.8107 1.02267 19.3334 1.66667 19.3334H2.83333C3.47733 19.3334 4 18.8107 4 18.1667V17.0001H18V18.1667C18 18.8107 18.5227 19.3334 19.1667 19.3334H20.3333C20.9773 19.3334 21.5 18.8107 21.5 18.1667V8.83341L19.1048 1.84858ZM4.96717 3.00008H17.034L18.6335 7.66675H3.3665L4.96717 3.00008ZM4.58333 13.5001C3.61733 13.5001 2.83333 12.7161 2.83333 11.7501C2.83333 10.7841 3.61733 10.0001 4.58333 10.0001C5.54933 10.0001 6.33333 10.7841 6.33333 11.7501C6.33333 12.7161 5.54933 13.5001 4.58333 13.5001ZM17.4167 13.5001C16.4507 13.5001 15.6667 12.7161 15.6667 11.7501C15.6667 10.7841 16.4507 10.0001 17.4167 10.0001C18.3827 10.0001 19.1667 10.7841 19.1667 11.7501C19.1667 12.7161 18.3827 13.5001 17.4167 13.5001Z"
        fill="#0E367D"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'statistics-blue'" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.166687 3.66699H2.66669V11.8337H0.166687V3.66699ZM4.83335 0.166992H7.16669V11.8337H4.83335V0.166992ZM9.50002 6.83366H11.8334V11.8337H9.50002V6.83366Z"
        fill="#0E367D"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'menu-blue'" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0V5.33333H5.33333V0H0ZM4 4H1.33333V1.33333H4V4ZM0 6.66667V12H5.33333V6.66667H0ZM4 10.6667H1.33333V8H4V10.6667ZM6.66667 0V5.33333H12V0H6.66667ZM10.6667 4H8V1.33333H10.6667V4ZM6.66667 6.66667V12H12V6.66667H6.66667ZM10.6667 10.6667H8V8H10.6667V10.6667Z"
        fill="#0E367D"
      />
    </svg>
    <svg v-if="icon === 'lock'" width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM11.1 7H4.9V5C4.9 3.29 6.29 1.9 8 1.9C9.71 1.9 11.1 3.29 11.1 5V7Z"
        fill="black"
        fill-opacity="0.54"
      />
    </svg>
    <svg v-if="icon === 'logout'" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 4L13.59 5.41L16.17 8H6V10H16.17L13.59 12.58L15 14L20 9L15 4ZM2 2H10V0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H10V16H2V2Z"
        fill="black"
        fill-opacity="0.54"
      />
    </svg>
    <svg v-if="icon === 'star'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 15.77L16.18 19.5L14.54 12.47L20 7.74L12.81 7.13L10 0.5L7.19 7.13L0 7.74L5.46 12.47L3.82 19.5L10 15.77Z" fill="black" fill-opacity="0.54" />
    </svg>
    <svg v-if="icon === 'golden-star'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 15.77L16.18 19.5L14.54 12.47L20 7.74L12.81 7.13L10 0.5L7.19 7.13L0 7.74L5.46 12.47L3.82 19.5L10 15.77Z" fill="#ffd700" />
    </svg>
    <svg v-if="icon === 'document'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 4H0V18C0 19.1 0.9 20 2 20H16V18H2V4ZM18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17 9H7V7H17V9ZM13 13H7V11H13V13ZM17 5H7V3H17V5Z"
        fill="black"
        fill-opacity="0.54"
      />
    </svg>
    <svg v-if="icon === 'account'" width="24" height="24" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 4A4 4 0 0 0 6 8A4 4 0 0 0 10 12A4 4 0 0 0 14 8A4 4 0 0 0 10 4M17 12C16.87 12 16.76 12.09 16.74 12.21L16.55 13.53C16.25 13.66 15.96 13.82 15.7 14L14.46 13.5C14.35 13.5 14.22 13.5 14.15 13.63L13.15 15.36C13.09 15.47 13.11 15.6 13.21 15.68L14.27 16.5C14.25 16.67 14.24 16.83 14.24 17C14.24 17.17 14.25 17.33 14.27 17.5L13.21 18.32C13.12 18.4 13.09 18.53 13.15 18.64L14.15 20.37C14.21 20.5 14.34 20.5 14.46 20.5L15.7 20C15.96 20.18 16.24 20.35 16.55 20.47L16.74 21.79C16.76 21.91 16.86 22 17 22H19C19.11 22 19.22 21.91 19.24 21.79L19.43 20.47C19.73 20.34 20 20.18 20.27 20L21.5 20.5C21.63 20.5 21.76 20.5 21.83 20.37L22.83 18.64C22.89 18.53 22.86 18.4 22.77 18.32L21.7 17.5C21.72 17.33 21.74 17.17 21.74 17C21.74 16.83 21.73 16.67 21.7 16.5L22.76 15.68C22.85 15.6 22.88 15.47 22.82 15.36L21.82 13.63C21.76 13.5 21.63 13.5 21.5 13.5L20.27 14C20 13.82 19.73 13.65 19.42 13.53L19.23 12.21C19.22 12.09 19.11 12 19 12H17M10 14C5.58 14 2 15.79 2 18V20H11.68A7 7 0 0 1 11 17A7 7 0 0 1 11.64 14.09C11.11 14.03 10.56 14 10 14M18 15.5C18.83 15.5 19.5 16.17 19.5 17C19.5 17.83 18.83 18.5 18 18.5C17.16 18.5 16.5 17.83 16.5 17C16.5 16.17 17.17 15.5 18 15.5Z"
        fill="black"
        fill-opacity="0.54"
      />
    </svg>
    <svg class="app--theme-color" v-if="icon === 'app-logo'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 0.666992C6.64 0.666992 0.666666 6.64033 0.666666 14.0003C0.666666 21.3603 6.64 27.3337 14 27.3337C21.36 27.3337 27.3333 21.3603 27.3333 14.0003C27.3333 6.64033 21.36 0.666992 14 0.666992ZM9.28 17.267L7.46667 16.4403C7.84 15.627 8.01333 14.787 8 13.9603C7.98667 13.1203 7.81333 12.307 7.46667 11.5603L9.28 10.7203C9.74667 11.7203 9.98667 12.8003 10 13.9203C10.0133 15.067 9.77333 16.187 9.28 17.267ZM13.3733 19.347L11.64 18.3603C12.3333 17.1337 12.68 15.7203 12.68 14.1603C12.68 12.5737 12.32 11.0537 11.6133 9.62699L13.4 8.73366C14.2533 10.4403 14.68 12.267 14.68 14.1603C14.68 16.067 14.24 17.8137 13.3733 19.347ZM17.56 21.107L15.76 20.227C16.8 18.0937 17.3333 15.987 17.3333 13.9737C17.3333 11.9603 16.8 9.88033 15.76 7.78699L17.5467 6.89366C18.7467 9.26699 19.3333 11.6403 19.3333 13.9737C19.3333 16.2937 18.7467 18.6937 17.56 21.107Z"
        fill="#0E367D"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    /**
     * Icon
     */
    icon: {
      type: String,
      default: "",
    },
  },
});
</script>

<style lang="scss" scoped>
.svg-icon {
  display: inline-block;
  vertical-align: middle;
}

svg.app--theme-color path {
  fill: rgb(var(--v-theme-themeColor)) !important;
}
</style>
