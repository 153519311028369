import axios, { AxiosInstance } from "axios";
import store from "@/store";
import router from "@/router";
import { apiUri } from "./uri";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
const controller = new AbortController();

let isRefreshing = false;
let failedQueue = [] as any;

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const requestInterceptors = async (httpClient: AxiosInstance, config: any) => {
  // add token to request header
  const token = store.getters.getToken || undefined;
  if (!config.headers) {
    config.headers = {};
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  //add local time to server, maybe need to detect timezone
  // config.headers!.XTime = new Date().getTime();
  //detect version
  // config.headers["Access-Control-Allow-Origin"] = "*";
  // config.headers["Access-Control-Allow-Headers"] = "*";
  // config.headers["Access-Control-Allow-Methods"] = "*";
  // config.headers!.Version = process.env.APP_VERSION || "V1";
  config.signal = controller.signal;

  return config;
};

export const responseInterceptors = async (httpClient: AxiosInstance, error: any) => {
  const errorStatus = error?.response?.data?.status || error?.response?.data?.statusCode || undefined;
  const code = error?.response?.data?.code || "";
  const currentRoute = router.currentRoute || undefined;
  const currentRouteName = (currentRoute?.value?.name || "") as string;
  const isExceptions = ["forgot_password", "login", "forgot_password_confirm", "logout", "register", "change_password", "register_success"];
  const originalRequest = error.config;

  if (isExceptions.indexOf(currentRouteName) < 0 && (errorStatus === 401 || code === "NotAuthorizedException") && !originalRequest.retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;
          return httpClient(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    originalRequest.retry = true;
    isRefreshing = true;
    return new Promise(function (resolve, reject) {
      axios
        .post(apiUri + "/v1/refresh-token", {
          refresh_token: cookies.get("refreshToken"),
        })
        .then(async ({ data }) => {
          const token = data.data.accessToken;
          store.dispatch("setToken", token);
          processQueue(null, token);
          resolve(httpClient(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
          // redirect logout here
          return router.push({ name: "logout" });
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error);
};

const requestBuilder = (baseUrl: string): AxiosInstance => {
  const httpClient = axios.create({
    baseURL: baseUrl,
  });

  httpClient.interceptors.request.use(
    (config: any) => requestInterceptors(httpClient, config),
    (error: any) => Promise.reject(error),
  );

  httpClient.interceptors.response.use(
    (response: any) => {
      //maybe parse response or check status code ...
      return response.data;
    },
    async (error: any) => responseInterceptors(httpClient, error),
  );

  return httpClient;
};
export default requestBuilder;
