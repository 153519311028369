import { NotificationMessageType } from "@/types";

export default {
  state: {
    type: "info",
    message: undefined,
  },
  getters: {},
  mutations: {
    SET_MESSAGE: (state: any, payload: NotificationMessageType) => (state = payload),
  },
  actions: {
    setMessage: (context: any, payload: NotificationMessageType) => context.commit("SET_MESSAGE", payload),
  },
};
