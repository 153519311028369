import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    table: {},
  },
  getters: {
    getTable: (state: any) => state.table || {},
  },
  mutations: {
    SET_ITEM: (state: any, payload: any) => (state.table[payload.packageAID] = payload),
    SET_ITEMS: (state: any, payload: any) => (state.table = payload || {}),
  },
  actions: {
    setItem: ({ commit }: ActionContext<any, any>, { packageAID, label }: any) => commit("SET_ITEM", { packageAID, label }),
    setItems: ({ commit }: ActionContext<any, any>, payload: any) => commit("SET_ITEMS", payload),
  },
};
