import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "py-2 app-main-navbar" }
const _hoisted_2 = { class: "container d-flex justify-start align-center text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
        return _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
          variant: "plain",
          key: item.title,
          class: _normalizeClass({ 'nav-item-active': item.active }),
          onClick: () => _ctx.onNaviagte(item.name)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_icon, {
              icon: item.icon,
              class: "d-flex align-center mr-2"
            }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(item.title), 1)
          ]),
          _: 2
        }, 1032, ["class", "onClick"])), [
          [_vShow, item.show]
        ])
      }), 128))
    ])
  ]))
}