import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_copyright = _resolveComponent("copyright")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createBlock(_component_v_footer, {
    class: "bg-transparent",
    padless: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "text-center pt-6" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_copyright, {
                color: "black",
                size: "small"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}