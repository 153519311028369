import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ecb6623"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-4 pa-6 login-form" }
const _hoisted_2 = { class: "login-text primary--text mb-6" }
const _hoisted_3 = {
  class: "mt-6 text-white text-center text-copyright",
  style: {"font-size":"12px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_copyright = _resolveComponent("copyright")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, [
        _renderSlot(_ctx.$slots, "title", {}, () => [
          _createTextVNode("default title")
        ], true)
      ]),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode("default slot")
      ], true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_copyright, { color: "black" })
      ])
    ])
  ]))
}