import request from "@/services/request";

export const getUsers = (params: any): Promise<any> => {
  return request.get("/v1/member", { params });
};

export const createUser = (param: any): Promise<any> => {
  return request.post("/v1/member", param);
};

export const updateUser = ({ email }: any, param: any): Promise<any> => {
  return request.put(`/v1/member/${email}`, param);
};

export const deleteUser = (params: any): Promise<any> => {
  return request.delete(`/v1/member`, { data: { ...params } });
};

export const exportUser = (params: any): Promise<any> => {
  return request.post("/v1/member/export", params, { responseType: "blob" });
};

export const getMfaCode = (params: any): Promise<any> => {
  return request.get("/v1/password-setting/generate-mfa-qr", { params });
};

export const updateMfaSatus = (param: any): Promise<any> => {
  return request.put("/v1/password-setting/reset-mfa", param);
};

export const verifyMfaCode = (param: any): Promise<any> => {
  return request.post("/v1/password-setting/verify-otp", param);
};

export const getCurrentUser = (): Promise<any> => {
  return request.get("/v1/me");
};

export const changePassword = ({ old_password, new_password }: any): Promise<any> => {
  return request.put("/v1/password-setting", { old_password, new_password });
};

export const getPermission = (): Promise<any> => {
  return request.get("/v1/permission");
};

export const getResourcesInit = (): Promise<any> => {
  return request.get("/v1/init");
};
