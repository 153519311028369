import { ref } from "vue";
export default function useCloudWatchLogs() {
  const items = ref<any[]>([]);
  const nextToken = ref<string | undefined>(undefined);

  const setItems = (list: any[]) =>
    items.value.push(
      ...list.map((item) => {
        const { message } = item;
        let rawMessage = message;
        try {
          rawMessage = JSON.parse(message);
        } catch (e) {
          // NOTE: JSONではないものは無視
        }

        return {
          title: message,
          created_at: new Date(item.timestamp).toISOString(),
          message: rawMessage,
        };
      }),
    );
  const setNextToken = (nt?: string) => (nextToken.value = nt);

  const reset = () => {
    items.value = [];
    nextToken.value = undefined;
  };

  return {
    items,
    setItems,
    nextToken,
    setNextToken,
    reset,
  };
}
