import { computed } from "vue";
import { useStore } from "vuex";
export default function useLoading() {
  const store = useStore();
  const loading = computed(() => store.state.loading.loading);
  const formLoading = computed(() => store.state.loading.formLoading);

  const setLoading = (payload: boolean) => store.dispatch("setLoading", payload);
  const setFormLoading = (payload: boolean) => store.dispatch("setFormLoading", payload);

  return {
    loading,
    formLoading,
    setLoading,
    setFormLoading,
  };
}
