export default {
  state: {
    filter: {
      name: undefined,
    },
    items: [],
  },
  getters: {},
  mutations: {
    APPLY_FILTER: (state: any, payload: any) => (state.filter = payload),
    SET_ROLES: (state: any, payload: any) => (state.items = payload),
  },
  actions: {
    applyFilter: (context: any, payload: any) => context.commit("APPLY_FILTER", payload),
    setRoles: (context: any, payload: any) => context.commit("SET_ROLES", payload),
  },
};
