<template>
  <p :class="`${textColor} ${colorClass} ${sizeClass}`">&nbsp;{{ APP_VERSION && !isProduction ? $t("app_version", { version: APP_VERSION }) : "" }}</p>
  <a target="_blank" :class="`${textColor} ${colorClass} ${sizeClass}`" :href="COPYRIGHT_URI">{{ $t("app_copyright") }} </a>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useConfig, useUserSetting } from "@/composables";

export default defineComponent({
  name: "CopyrightElem",
  props: {
    color: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    gray: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { COPYRIGHT_URI, APP_ENVIRONMENT_PROD, APP_ENV, APP_VERSION } = useConfig();
    const isProduction = computed(() => APP_ENV === APP_ENVIRONMENT_PROD);
    const colorClass = computed(() => {
      switch (props.color) {
        case "black":
          return "text-black";
        case "gray":
          return "text-grey";
        default:
          return "text-white";
      }
    });
    const sizeClass = computed(() => {
      switch (props.size) {
        case "small":
          return "small-text";
        default:
          return "";
      }
    });
    const textColor = computed(() => (props.gray ? "gray-text" : "text-white"));
    return {
      COPYRIGHT_URI,
      APP_VERSION,
      isProduction,
      colorClass,
      sizeClass,
      textColor,
    };
  },
});
</script>

<style scoped>
.small-text {
  font-size: 12px;
}
.gray-text {
  color: #4e4e4e;
}
</style>
