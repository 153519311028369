import { useI18n } from "vue-i18n";
import { useDateFormat } from "@/composables";

export default function useLogFormat() {
  const { t } = useI18n();
  const { showDate } = useDateFormat();

  const showActionLog = (obj: any) => {
    let requestParams: any = {};
    try {
      requestParams = JSON.parse(obj?.request_params);
    } catch (e: any) {
      requestParams = {};
    }
    const log: Record<string, any> = {
      [t("log.request_uri")]: obj?.url,
      [t("log.request_method")]: obj?.method,
      [t("log.request_params")]: requestParams,
    };
    if (obj?.request_by) {
      log[t("log.request_by")] = obj.request_by;
    }
    log[t("log.request_time")] = showDate(obj?.request_at || "", "YYYY-MM-DD HH:mm:ss");
    log[t("log.request_response")] = obj?.status;
    log[t("log.client_type")] = obj?.client_type;

    return log;
  };

  const showSimDataLog = (obj: any) => {
    const ret: Record<string, any> = {};
    let requestParams: any = {};
    try {
      requestParams = JSON.parse(obj?.request_params);
    } catch (e: any) {
      requestParams = {};
    }
    ret[t("log.request_uri")] = obj?.url;
    ret[t("log.request_method")] = obj?.method;
    ret[t("log.request_params")] = requestParams;
    if (obj && obj.user) ret[t("log.request_by")] = [obj?.user?.name, obj?.user?.email].filter((v: any) => v).join(" - ");
    ret[t("log.request_time")] = showDate(obj?.created_at || "", "YYYY-MM-DD HH:mm:ss");

    if (obj && "isSuccess" in obj) ret[t("log.request_response")] = obj?.isSuccess ? t("log.success") : t("log.error");
    return JSON.parse(JSON.stringify(ret, null, 8));
  };

  return {
    showActionLog,
    showSimDataLog,
  };
}
