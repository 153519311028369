import { computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useConfig, useLoading, useNotification, useFilter, useSort, useVuexPageLimit, useDateFormat } from "./";
import { getOtas } from "@/services/ota";

export default function useOTA() {
  const route = useRoute();
  const { t } = useI18n();
  const { SORT_NONE } = useConfig();
  const { setLoading } = useLoading();
  const { showError } = useNotification();
  const { timezoneOffset } = useDateFormat();
  const moduleName = "ota";

  const sortProps = computed(() => {
    return {
      id: SORT_NONE,
      label: SORT_NONE,
      version: SORT_NONE,
      builtAt: SORT_NONE,
      createdAt: SORT_NONE,
      updatedAt: SORT_NONE,
    };
  });
  const routePackageAID = computed(() => route.params.packageAID);
  const { items, totalPage, totalItem, take, takes, skip, currentPage, setTotalItem, setTotalPage, setItems, setLimit, setCurrentPage, resetPaginate } =
    useVuexPageLimit(moduleName);
  const { sortAttrs, sortAttrsValue, setSortColumnValue } = useSort(moduleName, sortProps.value, async () => {
    fetchPackages();
  });
  const { filterAttrs, setFilterQuery, setFilter } = useFilter(moduleName);
  const _setFilterQuery = async (attrs: any, packageAID: string, enableHistory = true) => {
    await setFilterQuery(attrs, { name: "ota_type", params: { packageAID } }, enableHistory);
  };

  const fetchPackages = async (packageAID?: string) => {
    try {
      setLoading(true);
      setItems([]);
      const response = await getOtas({
        packageAID: packageAID || routePackageAID.value,
        take: take.value,
        skip: skip.value,
        pageIndex: currentPage.value,
        ...sortAttrsValue.value,
        ...filterAttrs.value,
        timezoneOffset,
      });
      const { data, pageIndex, totalPages, totalItems } = response;
      setItems(data);
      setTotalItem(totalItems);
      setTotalPage(totalPages);
      setCurrentPage(pageIndex);
    } catch (e: any) {
      showError(e.response?.data?.message || e.message || t("ota.FetchListError"));
    } finally {
      setLoading(false);
    }
  };

  return {
    // pagination
    items,
    totalPage,
    totalItem,
    take,
    takes,
    skip,
    currentPage,
    setTotalItem,
    setTotalPage,
    setItems,
    setLimit,
    setCurrentPage,
    resetPaginate,
    // sort
    sortAttrs,
    sortAttrsValue,
    setSortColumnValue,
    // filter
    filterAttrs,
    setFilterQuery: _setFilterQuery,
    setStoreFilter: setFilter,

    fetchPackages,
  };
}
