const NTTCOM_CODE = process.env.NTTCOM_CODE || "3";
const COPYRIGHT_URI = process.env.COPYRIGHT_URI || "https://www.ntt.com/en/copyright.html";
const SOCKET_BASE_URI = process.env.VUE_APP_SOCKET_BASE_URL || `http://localhost:4000`;
const SOCKET_PATH = process.env.VUE_APP_SOCKET_PATH || ``;
const DEFAULT_DATE_DISPLAY_FORMAT = "MM-DD-YYYY HH:mm";
const SCHEDULE_ON = 1;
const SCHEDULE_OFF = 2;
const EXPIRATION_ON = 1;
const EXPIRATION_OFF = 2;
const SCHEDULE_DAILY = 5;
const SCHEDULE_WEEKLY = 4;
const SCHEDULE_LAST_MONTHLY = 3;
const SCHEDULE_MONTHLY = 2;
const SCHEDULE_SPECIFIED_DATE = 1;
const ROLE_ROOT = 1;
const ROLE_ADMIN = 2;
const ROLE_MEMBER = 3;
const SORT_ASC = "ASC";
const SORT_DESC = "DESC";
const SORT_NONE = "";

const EXPORT_EXTENSION = "csv";
const THEME_KEY = "system_theme";
const USER_CONFIG_LANGUAGE_KEY = "user_language";
const USER_CONFIG_TIMEZONE_AUTOMATION_KEY = "user_timezone_automation";
const USER_CONFIG_TIMEZONE_KEY = "user_config_timezone";
const SYSTEM_CONFIG_LOGO_KEY = "system_config_logo";
const SYSTEM_CONFIG_APP_NAME_KEY = "system_config_app_name";
const SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH_KEY = "system_password_minimum";
const SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH = 8;
const SYSTEM_CONFIG_PASSWORD_MAXIMUM_LENGTH = 64;
const SYSTEM_CONFIG_PASSWORD_CHARACTER_UPPER_CASE_KEY = "system_password_upper_case";
const SYSTEM_CONFIG_PASSWORD_CHARACTER_LOWER_CASE_KEY = "system_password_lower_case";
const SYSTEM_CONFIG_PASSWORD_CHARACTER_NUMBER_KEY = "system_password_number";
const SYSTEM_CONFIG_PASSWORD_CHARACTER_SYMBOL_KEY = "system_password_symbol";
const SYSTEM_CONFIG_GOOGLE_MAPS_API_KEY_KEY = "system_config_google_maps_api_key";
const SYSTEM_CONFIG_SIM_ONLINE_TERM_KEY = "system_config_sim_online_term";
const SYSTEM_CONFIG_PASSWORD_TRUE = "2";
const SYSTEM_CONFIG_PASSWORD_FALSE = "1";
const SYSTEM_CONFIG_TYPE = 1;
const USER_CONFIG_TYPE = 2;

const LANGUAGE_JA = "ja";
const LANGUAGE_EN = "en";
const SYSTEM_MAIL_KEY = "system_mail";
const NOTIFY_PASSWORD_KEY = "notify_password_invitation";
const NOTIFY_TOKEN_KEY = "notify_password_authentication";

const SYSTEM_LOGO_MAX_SIZE = 1000000; //byte = 1MB

const APP_VERSION = process.env.VUE_APP_VERSION || "-";
const APP_ENV = process.env.VUE_APP_ENV || undefined;
const APP_ENVIRONMENT_PROD = "prd";

const TRIGGER_CHANGE_PLAN_CODE = "APIPF_CHANGE_PLAN";
const TRIGGER_CHANGE_PHONE_NUMBER_CODE = "APIPF_CHANGE_PHONE_NUMBER";
const TRIGGER_CONTRACT_END_CODE = "APIPF_CONTRACT_END";
const TRIGGER_PLAN_EXPIRATION_CODE = "APIPF_PLAN_EXPIRATION";
const TRIGGER_DATA_USAGE_CODE = "APIPF_DATA_USAGE";
const TRIGGER_ALL_AIRTIME_CODE = "APIPF_ALL_AIR_TIME";
const TRIGGER_ALL_ROAMING_AIRTIME_ALL_CODE = "APIPF_ALL_ROAMING_AIR_TIME_ALL";
const TRIGGER_ALL_ROAMING_AIRTIME_ZONE_1_CODE = "APIPF_ALL_ROAMING_AIR_TIME_ZONE_1";
const TRIGGER_ALL_ROAMING_AIRTIME_ZONE_7_CODE = "APIPF_ALL_ROAMING_AIR_TIME_ZONE_7";
const TRIGGER_DATA_ROAMING_USAGE_CODE = "APIPF_DATA_ROAMING_USAGE";

const OFFICIAL_DOCUMENT_LINK = "https://sdpf.ntt.com/services/docs/icms/service-descriptions/applet/applet.html";

//ota tab
const KEY_TAB_POLLER = "poller";
const KEY_TAB_TELEMENTRY = "telemetry";
const KEY_TAB_CUSTOM = "custom";
const KEY_TAB_DEFAULT = KEY_TAB_POLLER;
const PACKAGE_AID_POLLER = "A0000001157000000000000053544F01";
const PACKAGE_AID_TELEMETRY = "A0000001157000000000000053544B01";
const PACKAGE_AID_MULTI_IMSI = "A0000001157000000000000052455301";
const PACKAGE_AID_IOT_SAFE = "A0000001157000000000000049534101";

import { DEFAULT_THEME } from "@/plugins/vuetify";

export default function useConfig() {
  return {
    NTTCOM_CODE,
    COPYRIGHT_URI,
    SOCKET_BASE_URI,
    SOCKET_PATH,
    DEFAULT_DATE_DISPLAY_FORMAT,
    SCHEDULE_ON,
    SCHEDULE_OFF,
    SCHEDULE_DAILY,
    SCHEDULE_WEEKLY,
    SCHEDULE_LAST_MONTHLY,
    SCHEDULE_MONTHLY,
    SCHEDULE_SPECIFIED_DATE,
    ROLE_ROOT,
    ROLE_ADMIN,
    ROLE_MEMBER,
    SORT_ASC,
    SORT_DESC,
    SORT_NONE,
    EXPORT_EXTENSION,
    EXPIRATION_ON,
    EXPIRATION_OFF,
    THEME_KEY,
    DEFAULT_THEME,
    USER_CONFIG_LANGUAGE_KEY,
    USER_CONFIG_TIMEZONE_AUTOMATION_KEY,
    USER_CONFIG_TIMEZONE_KEY,
    SYSTEM_CONFIG_LOGO_KEY,
    SYSTEM_CONFIG_APP_NAME_KEY,
    LANGUAGE_JA,
    LANGUAGE_EN,
    SYSTEM_MAIL_KEY,
    NOTIFY_PASSWORD_KEY,
    NOTIFY_TOKEN_KEY,
    SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH_KEY,
    SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH,
    SYSTEM_CONFIG_PASSWORD_MAXIMUM_LENGTH,
    SYSTEM_CONFIG_PASSWORD_CHARACTER_UPPER_CASE_KEY,
    SYSTEM_CONFIG_PASSWORD_CHARACTER_LOWER_CASE_KEY,
    SYSTEM_CONFIG_PASSWORD_CHARACTER_NUMBER_KEY,
    SYSTEM_CONFIG_PASSWORD_CHARACTER_SYMBOL_KEY,
    SYSTEM_CONFIG_PASSWORD_TRUE,
    SYSTEM_CONFIG_PASSWORD_FALSE,
    SYSTEM_CONFIG_TYPE,
    USER_CONFIG_TYPE,
    SYSTEM_LOGO_MAX_SIZE,
    APP_VERSION,
    APP_ENVIRONMENT_PROD,
    APP_ENV,
    SYSTEM_CONFIG_GOOGLE_MAPS_API_KEY_KEY,
    SYSTEM_CONFIG_SIM_ONLINE_TERM_KEY,
    TRIGGER_CHANGE_PLAN_CODE,
    TRIGGER_CHANGE_PHONE_NUMBER_CODE,
    TRIGGER_CONTRACT_END_CODE,
    TRIGGER_PLAN_EXPIRATION_CODE,
    TRIGGER_DATA_USAGE_CODE,
    TRIGGER_ALL_AIRTIME_CODE,
    TRIGGER_ALL_ROAMING_AIRTIME_ALL_CODE,
    TRIGGER_ALL_ROAMING_AIRTIME_ZONE_1_CODE,
    TRIGGER_ALL_ROAMING_AIRTIME_ZONE_7_CODE,
    TRIGGER_DATA_ROAMING_USAGE_CODE,
    OFFICIAL_DOCUMENT_LINK,
    KEY_TAB_POLLER,
    KEY_TAB_TELEMENTRY,
    KEY_TAB_CUSTOM,
    KEY_TAB_DEFAULT,
    PACKAGE_AID_POLLER,
    PACKAGE_AID_TELEMETRY,
    PACKAGE_AID_MULTI_IMSI,
    PACKAGE_AID_IOT_SAFE,
  };
}
