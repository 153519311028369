import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const meta = {
  auth: true,
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/SimManagement/SimListView.vue"),
    meta: meta,
  },
  {
    path: "/otas",
    name: "otas",
    component: () => import("@/views/OtaManagement/OtaView.vue"),
    meta: meta,
    children: [
      {
        path: "/otas",
        name: "ota_type_init",
        component: () => import("@/views/OtaManagement/OtaListView.vue"),
        meta: meta,
      },
      {
        path: "/otas/:packageAID",
        name: "ota_type",
        component: () => import("@/views/OtaManagement/OtaListView.vue"),
        meta: meta,
      },
      {
        path: "package/detail/:packageAID/:otaVersion",
        name: "ota_item",
        component: () => import("@/views/OtaManagement/OtaItemView.vue"),
        meta: meta,
      },
    ],
  },
  {
    path: "/sims",
    name: "sim",
    component: () => import("@/views/SimManagement/SimView.vue"),
    meta: meta,
    children: [
      {
        path: ":id/:cid",
        name: "sim_item",
        component: () => import("@/views/SimManagement/SimItemView.vue"),
        meta: meta,
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/SettingsView.vue"),
    meta: meta,
    children: [
      {
        path: "theme",
        name: "settingTheme",
        component: () => import("@/views/SettingsView.vue"),
        meta: meta,
      },
      {
        path: "log",
        name: "settingLog",
        component: () => import("@/views/SettingsView.vue"),
        meta: meta,
      },
      {
        path: "mail",
        name: "settingMail",
        component: () => import("@/views/SettingsView.vue"),
        meta: meta,
      },
      {
        path: "notify-password",
        name: "settingNotifyPassword",
        component: () => import("@/views/SettingsView.vue"),
        meta: meta,
      },
      {
        path: "password",
        name: "settingPassword",
        component: () => import("@/views/SettingsView.vue"),
        meta: meta,
      },
      {
        path: "generate",
        name: "settingGenerate",
        component: () => import("@/views/SettingsView.vue"),
        meta: meta,
      },
      {
        path: "access-restrictions",
        name: "settingAccessRestrictions",
        component: () => import("@/views/SettingsView.vue"),
        meta: meta,
      },
      {
        path: "setting-sim",
        name: "settingSim",
        component: () => import("@/views/SettingsView.vue"),
        meta: meta,
      },
    ],
  },
  {
    path: "/logs",
    name: "logs",
    component: () => import("@/views/LogsView.vue"),
    meta: meta,
    children: [
      {
        path: "system-log",
        name: "systemLog",
        component: () => import("@/views/LogsView.vue"),
        meta: meta,
      },
      {
        path: "raw-api-log",
        name: "rawApiLog",
        component: () => import("@/views/LogsView.vue"),
        meta: meta,
      },
      {
        path: "raw-proxy-log",
        name: "rawProxyLog",
        component: () => import("@/views/LogsView.vue"),
        meta: meta,
      },
      {
        path: "raw-ota-api-log",
        name: "rawOtaApiLog",
        component: () => import("@/views/LogsView.vue"),
        meta: meta,
      },
      {
        path: "raw-ota-service-log",
        name: "rawOtaServiceLog",
        component: () => import("@/views/LogsView.vue"),
        meta: meta,
      },
    ],
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/Authentication/SignUpView.vue"),
  },
  {
    path: "/account/register/success",
    name: "register_success",
    component: () => import("@/views/Authentication/SignUpSuccessView.vue"),
  },
  {
    path: "/change-password/:token",
    name: "change_password",
    component: () => import("@/views/Authentication/ChangePasswordView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Authentication/LoginView.vue"),
  },
  {
    path: "/verify_mfa",
    name: "verify_mfa",
    component: () => import("@/views/Authentication/VerifyMfaView.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/Authentication/LogoutView.vue"),
    meta: meta,
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: () => import("@/views/Authentication/ForgotPasswordView.vue"),
  },
  {
    path: "/forgot-password-confirm",
    name: "forgot_password_confirm",
    component: () => import("@/views/Authentication/ForgotPasswordConfirmView.vue"),
  },
  {
    path: "/members",
    name: "member_list",
    component: () => import("@/views/MemberManagement/MemberListView.vue"),
    meta: meta,
  },
  {
    path: "/personal/setting",
    name: "personal_setting",
    component: () => import("@/views/Authentication/MfaView.vue"),
    meta: meta,
  },
  { path: "/:pathMatch(.*)*", name: "not_found", component: () => import("@/views/NotFoundView.vue") },
  { path: "/apidocs", name: "apidoc", component: () => import("@/views/APISwagger/APISwaggerView.vue") },
  { path: "/openapi-docs", name: "openapi_doc", component: () => import("@/views/APISwagger/OpenAPISwaggerView.vue") },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
