import { ActionContext } from "vuex";
import { useI18n } from "vue-i18n";
import { JCLoadFileOrm } from "../../../common/src/models/postgres/jcLoadFile";
import { useConfig } from "@/composables";
import { ManagedPackageAID } from "@/types";
import { getListManagedPackageAIDs } from "@/services/ota";

export type Tab = {
  packageAID: string;
  label: string;
  enableDelete: boolean;
};
type State = {
  items: JCLoadFileOrm[];
  packageAIDs: ManagedPackageAID[];
};

export default {
  namespaced: true,
  state: {
    items: [] as JCLoadFileOrm[],
    packageAIDs: [] as ManagedPackageAID[],
  },
  getters: {
    getItems: (state: State) => state.items || [],
    getTabs: (state: State): Tab[] => {
      const { t } = useI18n();
      const { PACKAGE_AID_POLLER, PACKAGE_AID_TELEMETRY, PACKAGE_AID_MULTI_IMSI, PACKAGE_AID_IOT_SAFE } = useConfig();
      const tabs: Tab[] = [];
      [PACKAGE_AID_POLLER, PACKAGE_AID_TELEMETRY, PACKAGE_AID_MULTI_IMSI, PACKAGE_AID_IOT_SAFE].forEach((aid) => {
        const packageItem = state.packageAIDs.find((item) => item.packageAID === aid);
        if (packageItem) {
          let label = "";
          switch (aid) {
            case PACKAGE_AID_POLLER:
              label = t("ota.header.Poller");
              break;
            case PACKAGE_AID_TELEMETRY:
              label = t("ota.header.Telemetry");
              break;
            case PACKAGE_AID_MULTI_IMSI:
              label = t("ota.header.MultiIMSI");
              break;
            case PACKAGE_AID_IOT_SAFE:
              label = t("ota.header.IotSafe");
              break;
            default:
              break;
          }
          const enableDelete = aid !== PACKAGE_AID_POLLER;
          tabs.push({
            packageAID: aid,
            label,
            enableDelete,
          });
        }
      });
      for (const item of state.packageAIDs) {
        if (![PACKAGE_AID_POLLER, PACKAGE_AID_TELEMETRY, PACKAGE_AID_MULTI_IMSI, PACKAGE_AID_IOT_SAFE].includes(item.packageAID)) {
          const label = item.label || t("ota.header.Custom");
          tabs.push({
            packageAID: item.packageAID,
            label,
            enableDelete: true,
          });
        }
      }
      return tabs;
    },
    sortProps: () => {
      const { SORT_NONE } = useConfig();
      return {
        id: SORT_NONE,
        label: SORT_NONE,
        version: SORT_NONE,
        builtAt: SORT_NONE,
        createdAt: SORT_NONE,
        updatedAt: SORT_NONE,
      };
    },
  },
  mutations: {
    SET_ITEMS: (state: any, payload: JCLoadFileOrm[]) => (state.items = payload),
    SET_PACKAGE_AIDS: (state: State, payload: ManagedPackageAID[]) => (state.packageAIDs = payload),
  },
  actions: {
    fetchPackageAIDs: async ({ commit }: ActionContext<any, any>) => {
      const packageAIDs = await getListManagedPackageAIDs();
      commit("SET_PACKAGE_AIDS", packageAIDs);
    },
  },
};
