import { createOrUpdateUserSettings, getUserSettings, createOrUpdateLanguageSetting } from "@/services/user-setting";
import { computed } from "vue";
import { useStore } from "vuex";
import useConfig from "./useConfig";
import { useI18n } from "vue-i18n";

export default function useUserSetting() {
  const { locale } = useI18n();

  const sort = (a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();

  const store = useStore();
  const {
    SYSTEM_CONFIG_APP_NAME_KEY,
    SYSTEM_CONFIG_TYPE,
    SYSTEM_CONFIG_LOGO_KEY,
    SYSTEM_CONFIG_GOOGLE_MAPS_API_KEY_KEY,
    USER_CONFIG_LANGUAGE_KEY,
    SYSTEM_CONFIG_SIM_ONLINE_TERM_KEY,
  } = useConfig();
  const userId = computed(() => store.getters.getUserId);

  const list = computed(() => store.getters.getUserSettings);
  const settings = computed(() => list.value?.filter((s: any) => s.type == SYSTEM_CONFIG_TYPE || s.created_by == userId.value));
  const sortedSettings = computed(() => settings.value?.sort(sort));
  const appLogo = computed<any>(() => {
    const logo = getUserSettingByKey(SYSTEM_CONFIG_LOGO_KEY);
    if (logo && logo.value && logo.value.length && logo.value != "null") {
      try {
        return JSON.parse(logo.value);
      } catch (e: any) {
        //do something
      }
    }
    return undefined;
  });

  const appName = computed(() => {
    const name = getUserSettingByKey(SYSTEM_CONFIG_APP_NAME_KEY);
    if (name && name.value && name.value != "null") {
      return name.value;
    }
    return "Applet Console";
  });

  const googleMapApiKey = computed(() => {
    const apiKey = getUserSettingByKey(SYSTEM_CONFIG_GOOGLE_MAPS_API_KEY_KEY);
    if (apiKey && apiKey.value) {
      return apiKey.value;
    }
    return null;
  });

  const simOnlineTerm = computed(() => {
    const setting = getUserSettingByKey(SYSTEM_CONFIG_SIM_ONLINE_TERM_KEY);
    if (setting && setting.value) {
      return parseInt(setting.value);
    }
    return 15;
  });

  const language = computed(() => {
    const setting = getUserSettingByKey(USER_CONFIG_LANGUAGE_KEY);
    return setting?.value || "ja";
  });
  const originalLanguage = computed(() => store.getters.getOriginalLanguage || "ja");

  const setLocale = () => {
    const settingLocale = getUserSettingByKey(USER_CONFIG_LANGUAGE_KEY);
    locale.value = settingLocale?.value || store.getters.getLanguage || "ja";
  };

  const fetchUserSettings = async () => {
    const { data } = await getUserSettings({});
    store.dispatch("setUserSettings", data);
    return data;
  };

  const saveUserSetting = async (key: string, value: any) => {
    await createOrUpdateUserSettings([
      {
        key: key,
        value: String(value),
      },
    ]);
    await fetchUserSettings();
  };

  const saveUserSettings = async (params: any[]) => {
    await createOrUpdateUserSettings(params);
    await fetchUserSettings();
  };

  const saveLanguageSetting = async (params: any[]) => {
    await createOrUpdateLanguageSetting(params);
    await fetchUserSettings();
  };

  const getUserSettingByKey = (key: string) => sortedSettings.value?.find((c: any) => c.key === key);
  const getUserSettingByKeys = (keys: string[]) => sortedSettings.value?.filter((c: any) => keys.indexOf(c.key) >= 0);

  return {
    settings,
    sortedSettings,
    appLogo,
    appName,
    googleMapApiKey,
    simOnlineTerm,
    language,
    originalLanguage,
    saveUserSetting,
    saveUserSettings,
    fetchUserSettings,
    getUserSettingByKey,
    getUserSettingByKeys,
    setLocale,
    saveLanguageSetting,
  };
}
