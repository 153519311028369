import { computed } from "vue";
import { useStore } from "vuex";
import { State } from "@/store/table-view";

export default function useTableView(module: string) {
  const store = useStore();
  const state = computed<State>(() => store.state.tableView);

  const isShowColmun = (col: string) => state.value.module[module].columns.includes(col);
  const setColumns = (columns: string[]) => store.dispatch("setModuleColumns", { module, columns });
  const columns = computed({
    get: () => state.value.module[module].columns || [],
    set: (cols: string[]) => setColumns(cols),
  });

  return {
    isShowColmun,
    setColumns,
    columns,
  };
}
