import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

import useConfig from "./useConfig";

export default function useDateFormat() {
  const { DEFAULT_DATE_DISPLAY_FORMAT } = useConfig();
  const showDate = (value: any, format: string, isLocalTime = true) => {
    if (!format) {
      format = DEFAULT_DATE_DISPLAY_FORMAT;
    }
    if (!value || (typeof value === "string" && value.length <= 0)) {
      return value;
    }
    if (typeof value === "number") {
      return dayjs.unix(value).utc(isLocalTime).format(format);
    }
    return dayjs(value).utc(isLocalTime).format(format);
  };

  const getDate = () => dayjs();
  const getUnixNow = () => dayjs().unix();
  const getCurrentYear = () => dayjs().year();
  const generateYearRange = (start: number, end: number) => {
    const currentYear = getCurrentYear();
    const arr = [];
    for (let i = currentYear + start; i <= currentYear + end; i++) {
      arr.push({
        id: i,
        name: i,
      });
    }
    return arr;
  };

  const convertTimezoneOffsetToUtc = (zone: string) => {
    return dayjs("2022-07-22 12:00").tz(zone).format("Z");
  };

  const now = new Date();
  const timezoneOffset = -(now.getTimezoneOffset() / 60);

  return {
    showDate,
    getDate,
    getUnixNow,
    getCurrentYear,
    generateYearRange,
    convertTimezoneOffsetToUtc,
    timezoneOffset,
  };
}
