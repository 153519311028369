export default {
  state: {
    loading: false,
    formLoading: false,
  },
  getters: {},
  mutations: {
    SET_LOADING: (state: any, payload: boolean) => (state.loading = payload),
    SET_FORM_LOADING: (state: any, payload: boolean) => (state.formLoading = payload),
  },
  actions: {
    setLoading: (context: any, payload: boolean) => context.commit("SET_LOADING", payload),
    setFormLoading: (context: any, payload: boolean) => context.commit("SET_FORM_LOADING", payload),
  },
};
