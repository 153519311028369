import { computed } from "vue";
import { useTheme } from "vuetify";
import { useStore } from "vuex";
import useConfig from "./useConfig";

export default function useAppTheme() {
  const sortConfig = (a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  const store = useStore();
  const { DEFAULT_THEME, THEME_KEY } = useConfig();
  const config = computed(() => store.getters.getUserSettings);
  const selectedTheme = computed(() => store.getters.getSelectedTheme);
  const sortedConfig = computed(() => config.value?.sort(sortConfig));
  const storeTheme = computed(() => sortedConfig.value?.find((c: any) => c.key === THEME_KEY));

  const storeThemeName = computed(() => storeTheme.value?.value || DEFAULT_THEME);

  const theme = useTheme();
  const { themes, name, current, computedThemes } = theme;

  const isThemeValid = (name: string) => themes.value[name] || false;
  const setTheme = (themeName: any) => {
    theme.global.name.value = themeName;
    // NOTE: scrollbarやaタグの配色などはダークテーマカラーにしない
    theme.global.current.value.dark = false;
  };

  const setSelectedTheme = (themeName: string) => {
    store.dispatch("setSelectedTheme", themeName);
  };

  return {
    userConfig: config,
    storeThemeName,
    selectedTheme,
    themes,
    themeName: name,
    currentTheme: current,
    computedThemes,
    DEFAULT_THEME,
    THEME_KEY,
    setTheme,
    isThemeValid,
    setSelectedTheme,
  };
}
