export default {
  state: {
    isBusy: false,
  },
  getters: {},
  mutations: {
    SET_BUSY: (state: any, payload: boolean) => (state.isBusy = payload),
  },
  actions: {
    setBusy: (context: any, payload: boolean) => context.commit("SET_BUSY", payload),
  },
};
