type ViewConfig = {
  columns: string[];
};
export type State = {
  module: Record<string, ViewConfig>;
};
export default {
  state: {
    module: {
      sim: {
        // default display columns
        columns: [
          "device_alias",
          "connection",
          "poller_version",
          "status_poller",
          "other_applet_name",
          "other_applet_version",
          "status_other_applet",
          "status",
        ],
      },
    },
  },
  getters: {},
  mutations: {
    SET_MODULE_COLUMNS: (state: State, payload: { module: string; columns: string[] }) => (state.module[payload.module].columns = payload.columns),
  },
  actions: {
    setModuleColumns: (context: { state: State; commit: any }, payload: { module: string; columns: string[] }) => {
      context.commit("SET_MODULE_COLUMNS", payload);
    },
  },
};
