import { ref, computed } from "vue";
import { useStore } from "vuex";
import { State } from "@/store/paginate";

export default function usePageLimit(currentModule = "default") {
  const store = useStore();
  const state = computed<State>(() => store.state.paginate);
  const module = computed(() => currentModule || "default");
  if (!state.value[module.value] || typeof state.value[module.value] === "number") {
    store.dispatch("initModulePaginate", module.value);
  }
  const take = computed(() => state.value.module[module.value].limit || 20);
  const takes = ref<number[]>([5, 10, 15, 20, 25, 50, 100]);
  const totalPage = computed(() => state.value.module[module.value].totalPage || 0);
  const totalItem = computed(() => state.value.module[module.value].totalItem || 0);
  const items = computed(() => store.getters[`${module.value}/getItems`]);
  const updatedIndex = computed(() => store.getters[`${module.value}/getUpdatedIndex`]);
  const currentPage = computed(() => state.value.module[module.value].currentPage || 1);
  const skip = computed(() => (currentPage.value - 1) * take.value);

  const setCurrentPage = (currentPage: number) => store.dispatch("setModuleCurrentPage", { module: module.value, currentPage });
  const setTotalPage = (totalPage: number) => store.dispatch("setModuleTotalPage", { module: module.value, totalPage });
  const setTotalItem = (totalItem: number) => store.dispatch("setModuleTotalItem", { module: module.value, totalItem });
  const setItems = (list: any[]) => store.commit(`${module.value}/SET_ITEMS`, list);
  const setLimit = (limit: number) => store.dispatch("setModuleLimit", { module: module.value, limit });
  const resetPaginate = () => store.dispatch("initModulePaginate", module.value);

  return {
    items,
    updatedIndex,
    takes,
    take,
    skip,
    totalPage,
    totalItem,
    currentPage,
    setCurrentPage,
    setTotalPage,
    setTotalItem,
    setItems,
    setLimit,
    resetPaginate,
  };
}
