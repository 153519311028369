import { useCookies } from "vue3-cookies";
import request, { rawEnv, onTokenRefreshError } from "@/services/request";
import store from "@/store";
import { LoginType, LoginResponseType, ForgotPasswordRequestType, VerifyMfaType, ForgotPasswordType } from "@/types";

import { AxiosError, AxiosResponse } from "axios";

const { cookies } = useCookies();

export const login = (param: LoginType): Promise<LoginResponseType> => {
  return request.post("/v1/login", param);
};

export const verifyMfa = (param: VerifyMfaType): Promise<LoginResponseType> => {
  return request.post("/v1/verify-otp", param);
};

export const forgotPasswordRequest = (param: ForgotPasswordRequestType) => {
  return request.post("/v1/forget-password", param);
};

export const forgotPasswordReset = (param: ForgotPasswordType) => {
  return request.put("/v1/forget-password", param);
};

export const logout = (): Promise<any> => {
  return request.post("/v1/logout");
};

export const refreshToken = async () => {
  const accessToken = store.getters.getToken || undefined;
  const refreshToken = cookies.get("refreshToken");
  if (!refreshToken || !accessToken) {
    onTokenRefreshError();
    stopRefreshTokenTimer();
    return null;
  }

  let res: AxiosResponse | undefined;
  try {
    res = await request.post(
      "/v1/refresh-token",
      {
        refresh_token: refreshToken,
      },
      {
        env: rawEnv,
      },
    );
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      res = e.response;
    } else {
      console.error(e);
    }
  }
  if (res?.status !== 200) {
    onTokenRefreshError(res);
    return null;
  }
  const token = res.data.data.accessToken;
  store.dispatch("setToken", token);
  return res;
};

export const register = (param: any): Promise<any> => {
  return request.post("/v1/register", param);
};

export const changeTempPassword = (param: any): Promise<any> => {
  return request.post("/v1/confirm-password", param);
};

let tokenInterval: NodeJS.Timer | undefined;
export const startRefreshTokenTimer = () => {
  if (!tokenInterval) {
    tokenInterval = setInterval(() => refreshToken(), 2 * 60 * 1000);
  }
};

export const stopRefreshTokenTimer = () => {
  if (tokenInterval) {
    clearInterval(tokenInterval);
    tokenInterval = undefined;
  }
};
