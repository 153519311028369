import { reactive, computed } from "vue";
import useConfig from "./useConfig";
import { useStore } from "vuex";

export default function useSort(currentModule = "default", props: any, callback?: (key: string, from: string, to: string, attr: any) => any | void) {
  const { SORT_ASC, SORT_DESC, SORT_NONE } = useConfig();
  const attrs = reactive(props);
  const store = useStore();
  const module = computed(() => currentModule || "default");

  const resetSort = async () => {
    for (const prop in attrs) {
      attrs[prop] = SORT_NONE;
    }
  };

  const attrsHasValue = computed(() => {
    const obj = Object.assign({}, attrs);
    for (const prop in obj) {
      if (obj[prop] !== SORT_NONE) {
        return {
          sortBy: prop,
          sortType: obj[prop],
        };
      }
    }
    return {};
  });

  const setSortColumnValue = async (key: string, value?: string) => {
    const currentValue = attrs[key as keyof typeof attrs] || SORT_NONE;
    const nextValue = value || currentValue === SORT_NONE ? SORT_ASC : currentValue === SORT_ASC ? SORT_DESC : SORT_NONE;
    if (nextValue !== SORT_NONE) {
      store.dispatch("setModuleSort", { module: module.value, sort: { sort_by: key, sort_order: nextValue } });
    } else {
      store.dispatch("setModuleSort", { module: module.value, sort: undefined });
    }
    await resetSort();
    attrs[key as keyof typeof attrs] = nextValue;
    if (callback) {
      callback(key, currentValue, nextValue, attrsHasValue.value);
    }
  };

  const setSortColumnDefault = async (key: string) => {
    const currentValue = attrs[key as keyof typeof attrs] || SORT_NONE;
    const nextValue = currentValue === SORT_NONE ? SORT_ASC : currentValue === SORT_ASC ? SORT_DESC : SORT_NONE;
    await resetSort();
    attrs[key as keyof typeof attrs] = nextValue;
    if (callback) {
      callback(key, currentValue, nextValue, attrsHasValue.value);
    }
  };

  return {
    sortAttrs: attrs,
    sortAttrsValue: attrsHasValue,
    resetSort,
    setSortColumnValue,
    setSortColumnDefault,
  };
}
