import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import store from "@/store";

const createRouteBeforeEach = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const meta = to.meta || {};
  const requiresAuth = meta.auth || false;
  const role = meta.role || undefined;
  const isLoginRoute = to.name?.toString() === "login";
  const isLogoutRoute = to.name?.toString() === "logout";
  const authenRoutes = ["register", "register_success", "change_password", "login", "verify_mfa", "forgot_password", "forgot_password_confirm"];
  const isAuthenRoute = to.name ? authenRoutes.includes(to.name?.toString()) : false;

  const isAuthenticated = store.getters.isAuthenticated || false;
  const user = store.getters.getUser || undefined;
  if (user && user.role_id === role) {
    next({ name: "home" });
  }
  if (isAuthenticated && (isLoginRoute || isAuthenRoute)) {
    next({ name: "home" });
  } else if (requiresAuth && !isAuthenticated) {
    next({ name: "login", query: { redirect_uri: isLoginRoute || isLogoutRoute ? "/" : to.fullPath } });
  } else next();
};

export default createRouteBeforeEach;
