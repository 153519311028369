import { createI18n } from "vue-i18n";
import enUS from "@/locales/en.json";
import jajP from "@/locales/ja.json";

export default createI18n({
  globalInjection: true,
  legacy: false,
  locale: "ja",
  messages: {
    en: enUS,
    ja: jajP,
  },
});
