// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "@/assets/override.scss";

// Vuetify
import { createVuetify } from "vuetify";

const themeBase: any = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    error: "#B00020",
    info: "#0288D1",
    "on-surface-variant": "#EEEEEE",
    primary: "#0E367D",
    theme: "#0E367D",
    "primary-darken-1": "#3700B3",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    success: "#4CAF50",
    surface: "#FFFFFF",
    "surface-variant": "#424242",
    warning: "#FB8C00",
    gray: "#F3F4F6",
    blue: "#0E367D",
    "border-color": "#edeff1",
    grayLight: "#000",
    guide: "#0288D1",
    formControl: "#0E367D",
    navBar: "#0a2658",
    black: "#000",
  },
  variables: {
    "activated-opacity": 0.12,
    "border-color": "#000000",
    "border-opacity": 0.12,
    "code-background-color": "#C2C2C2",
    "disabled-opacity": 0.38,
    "dragged-opacity": 0.08,
    "focus-opacity": 0.12,
    "high-emphasis-opacity": 0.87,
    "hover-opacity": 0.04,
    "idle-opacity": 0.04,
    "kbd-background-color": "#212529",
    "kbd-color": "#FFFFFF",
    "medium-emphasis-opacity": 0.6,
    "pressed-opacity": 0.12,
    "selected-opacity": 0.08,
    grayLightOpacity: 0.6,
  },
};
const themes = {};
[
  { name: "light", options: { dark: false, colors: { themeColor: "#0E367D" } } },
  { name: "red", options: { dark: false, colors: { themeColor: "#C62828" } } },
  { name: "pink", options: { dark: false, colors: { themeColor: "#AD1457" } } },
  { name: "purple", options: { dark: false, colors: { themeColor: "#6A1B9A" } } },
  { name: "theme_1", options: { dark: false, colors: { themeColor: "#4527A0" } } },
  { name: "theme_2", options: { dark: false, colors: { themeColor: "#283593" } } },
  { name: "theme_3", options: { dark: false, colors: { themeColor: "#1565C0" } } },
  { name: "theme_4", options: { dark: false, colors: { themeColor: "#0277BD" } } },
  { name: "theme_5", options: { dark: false, colors: { themeColor: "#00838F" } } },
  { name: "theme_6", options: { dark: false, colors: { themeColor: "#00695C" } } },
  { name: "theme_7", options: { dark: false, colors: { themeColor: "#2E7D32" } } },
  { name: "theme_8", options: { dark: false, colors: { themeColor: "#4E342E" } } },
  { name: "dark", options: { dark: true, colors: { themeColor: "#424242" } } },
  { name: "theme_9", options: { dark: false, colors: { themeColor: "#37474F" } } },
].map(
  (t: any) =>
    (themes[t.name] = {
      ...{ ...themeBase, ...t.options },
      colors: { ...themeBase.colors, ...(t.options.colors || {}) },
      variables: { ...themeBase.variables, ...(t.options.variables || {}) },
    }),
);

export const DEFAULT_THEME = "light";

export default createVuetify({
  theme: {
    defaultTheme: DEFAULT_THEME,
    themes: themes,
  },
  defaults: {
    VTextField: {
      density: "comfortable",
    },
    VSelect: {
      density: "comfortable",
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
