import { useI18n } from "vue-i18n";

export default function useRSRQ() {
  const { t } = useI18n();
  const RSRQ_CRITERIA = {
    EXCELLENT: -5,
    VERY_GOOD: -8,
    GOOD: -10,
    POOR: -13,
  };

  const getText = (rsrq: number): string => {
    if (typeof rsrq !== "number") {
      return "";
    }
    if (rsrq >= RSRQ_CRITERIA.EXCELLENT) {
      return t("sim_rsrq.excellent");
    } else if (rsrq >= RSRQ_CRITERIA.VERY_GOOD) {
      return t("sim_rsrq.very_good");
    } else if (rsrq >= RSRQ_CRITERIA.GOOD) {
      return t("sim_rsrq.good");
    } else if (rsrq >= RSRQ_CRITERIA.POOR) {
      return t("sim_rsrq.poor");
    } else {
      return t("sim_rsrq.very_poor");
    }
  };

  const isVeryPoor = (rsrq: number): boolean => {
    if (typeof rsrq !== "number") {
      return false;
    }
    return rsrq < RSRQ_CRITERIA.POOR;
  };

  return {
    getText,
    isVeryPoor,
  };
}
