<template>
  <v-app class="mh-100">
    <v-theme-provider with-background class="mh-100">
      <v-layout class="mh-100">
        <busy-page v-if="isBusy" />
        <v-main v-else class="bg-app-gray">
          <div v-show="key > 0 && isAuthenticated && isAppInitialized && !isApiDoc && !isOpenApiDoc">
            <header-bar @render="rerenderChildComponent" />
            <nav-bar />
          </div>
          <div class="content-wrapper" :class="{ 'pb-50': isAuthenticated && !isApiDoc && !isOpenApiDoc }">
            <router-view v-if="(!isAuthenticated || !isLogout || key > 0) && isAppInitialized" :key="key" />
          </div>
          <div class="footer w-100" v-show="key > 0 && isAuthenticated && isAppInitialized && !isApiDoc && !isOpenApiDoc">
            <footer-bar />
          </div>
          <router-view v-if="isAuthPage || isLogout" :key="key" />
        </v-main>
      </v-layout>
    </v-theme-provider>
    <app-loading />
  </v-app>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, watch, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import "@/assets/style.scss";
import HeaderBar from "@/components/BaseComponent/Header.vue";
import NavBar from "@/components/BaseComponent/NavBar.vue";
import FooterBar from "@/components/BaseComponent/Footer.vue";
import AppLoading from "@/components/BaseComponent/AppLoading.vue";
import BusyPage from "@/components/Common/BusyPage.vue";
import { getCurrentUser, getResourcesInit } from "@/services/user";
import { useRoute } from "vue-router";
import { useAppTheme, useUserSetting, useNotification, useConfig, useLoading } from "@/composables";
import { refreshToken, startRefreshTokenTimer } from "./services/auth";
import router from "@/router";
import { AxiosError } from "axios";

export default defineComponent({
  name: "App",

  components: {
    HeaderBar,
    NavBar,
    FooterBar,
    AppLoading,
    BusyPage,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { setTheme, setSelectedTheme, storeThemeName } = useAppTheme();
    const { setLocale, getUserSettingByKey } = useUserSetting();
    const { USER_CONFIG_LANGUAGE_KEY, LANGUAGE_JA } = useConfig();
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const isApiDoc = computed(() => useRoute().name == "apidoc");
    const isOpenApiDoc = computed(() => useRoute().name == "openapi_doc");
    const isLogin = computed(() => useRoute().name == "login");
    const isLogout = computed(() => useRoute().name == "logout");
    const authenRoutes = ["register", "register_success", "change_password", "login", "verify_mfa", "forgot_password", "forgot_password_confirm"];
    const isAuthPage = computed(() => {
      const routeName = useRoute().name || "";
      return authenRoutes.includes(routeName.toString());
    });
    const { showError } = useNotification();
    const key = ref(0);
    const { setLoading } = useLoading();
    const isBusy = computed(() => store.state.busy.isBusy);
    const isAppInitialized = ref(false);

    const rerenderChildComponent = () => {
      key.value++;
    };

    const fetchUser = async () => {
      if (!isAuthenticated.value) {
        return;
      }
      try {
        const { data } = await getCurrentUser();
        console.log(data);
        store.dispatch("setUser", data);

        let permissions = [] as any;
        data.role.permissions.forEach((element: any) => {
          let permission_name = element.permission.name;
          permissions.push(permission_name);
        });
        store.dispatch("setPermission", permissions);
      } catch (e: any) {
        showError(e.response?.data?.message || t("GetUserProfileError"));
      }
    };

    const fetchResources = async () => {
      if (!isAuthenticated.value) {
        return;
      }
      try {
        const { data } = await getResourcesInit();

        await store.dispatch("setUserSettings", data.configs);
        await store.dispatch("setRoles", data.roles);
        const userLanguage = getUserSettingByKey(USER_CONFIG_LANGUAGE_KEY);
        const language = userLanguage?.value || LANGUAGE_JA;
        await store.dispatch("setLanguage", language);
        await store.dispatch("setOriginalLanguage", language);
        setLocale();
        return data;
      } catch (e: any) {
        showError(e.response?.data?.message || t("GetUserProfileError"));
        if (e instanceof AxiosError && e.response?.status === 401) {
          router.push({ name: "logout" });
        } else {
          store.dispatch("setBusy", true);
        }
      } finally {
        // NOTE: ブラウザのロード時に言語設定が反映されないケースがあるので言語変更後に子コンポーネントを再マウントさせるための処理をしている
        rerenderChildComponent();
      }
    };

    const connectWebSocket = async (env: string) => {
      if (!isAuthenticated.value) {
        return;
      }
      store.dispatch("websocket/connect", { env });
    };

    const disconnectWebSocket = async () => {
      if (isAuthenticated.value) {
        return;
      }
      store.dispatch("websocket/disconnect");
    };

    const appInit = async () => {
      try {
        if (!isAuthenticated.value) {
          router.push({ name: "logout" });
          return;
        }

        setLoading(true);

        const tokenStatus = await refreshToken();
        if (!tokenStatus) {
          return;
        }
        startRefreshTokenTimer();

        const [_, init] = await Promise.allSettled([fetchUser(), fetchResources()]);
        const env = (init as PromiseFulfilledResult<any>).value?.env || "";
        connectWebSocket(env);
        isAppInitialized.value = true;
      } catch (e: any) {
        showError(e.response?.data?.message || t("GetUserProfileError"));
        router.push({ name: "login" });
      } finally {
        setLoading(false);
      }
    };

    onBeforeMount(async () => {
      appInit();
    });

    watch(
      () => storeThemeName.value,
      () => {
        setTheme(storeThemeName.value);
        setSelectedTheme(storeThemeName.value);
      },
    );
    watch(
      () => isAuthenticated.value,
      () => {
        if (isAppInitialized.value && !isAuthenticated.value) {
          isAppInitialized.value = false;
        }
        disconnectWebSocket();
        appInit();
      },
    );

    onMounted(() => {
      setTheme(storeThemeName.value);
      setSelectedTheme(storeThemeName.value);
    });

    onBeforeUnmount(async () => {
      await disconnectWebSocket();
    });

    return {
      isAuthenticated,
      isApiDoc,
      isOpenApiDoc,
      isLogin,
      isLogout,
      storeThemeName,
      key,
      rerenderChildComponent,
      isBusy,
      isAppInitialized,
      isAuthPage,
    };
  },
});
</script>
<style scoped>
.footer {
  position: absolute;
  bottom: 0;
}
.pb-50 {
  padding-bottom: 50px;
}
</style>
