import { computed } from "vue";
import { useI18n } from "vue-i18n";
import useConfig from "./useConfig";
import useUserSetting from "./useUserSetting";

export default function usePasswordRule() {
  const {
    SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH_KEY,
    SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH,
    SYSTEM_CONFIG_PASSWORD_MAXIMUM_LENGTH,
    SYSTEM_CONFIG_PASSWORD_CHARACTER_UPPER_CASE_KEY,
    SYSTEM_CONFIG_PASSWORD_CHARACTER_LOWER_CASE_KEY,
    SYSTEM_CONFIG_PASSWORD_CHARACTER_NUMBER_KEY,
    SYSTEM_CONFIG_PASSWORD_CHARACTER_SYMBOL_KEY,
    SYSTEM_CONFIG_PASSWORD_TRUE,
  } = useConfig();
  const { t } = useI18n();

  const { getUserSettingByKey } = useUserSetting();

  const minRule = computed<number>(() => getUserSettingByKey(SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH_KEY)?.value || SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH);
  const upperRule = computed<string>(() => getUserSettingByKey(SYSTEM_CONFIG_PASSWORD_CHARACTER_UPPER_CASE_KEY)?.value || SYSTEM_CONFIG_PASSWORD_TRUE);
  const lowerRule = computed<string>(() => getUserSettingByKey(SYSTEM_CONFIG_PASSWORD_CHARACTER_LOWER_CASE_KEY)?.value || SYSTEM_CONFIG_PASSWORD_TRUE);
  const numberRule = computed<string>(() => getUserSettingByKey(SYSTEM_CONFIG_PASSWORD_CHARACTER_NUMBER_KEY)?.value || SYSTEM_CONFIG_PASSWORD_TRUE);
  const symbolRule = computed<string>(() => getUserSettingByKey(SYSTEM_CONFIG_PASSWORD_CHARACTER_SYMBOL_KEY)?.value || SYSTEM_CONFIG_PASSWORD_TRUE);

  const regexp = computed<RegExp>(() => {
    let regex = "";
    if (lowerRule.value == SYSTEM_CONFIG_PASSWORD_TRUE) {
      regex += "(?=.*[a-z])";
    }
    if (upperRule.value == SYSTEM_CONFIG_PASSWORD_TRUE) {
      regex += "(?=.*[A-Z])";
    }
    if (numberRule.value == SYSTEM_CONFIG_PASSWORD_TRUE) {
      regex += "(?=.*\\d)";
    }
    if (symbolRule.value == SYSTEM_CONFIG_PASSWORD_TRUE) {
      regex += "(?=.*[!@#$%^&*()_+])";
    }
    regex += `(?=.{${minRule.value},${SYSTEM_CONFIG_PASSWORD_MAXIMUM_LENGTH}})`;

    return new RegExp(`^${regex}`);
  });

  const passwordRegexMessage = computed(() => {
    let message = t("passwordRule.passwordLength", { min: minRule.value });
    const includeMessage = [upperRule.value, lowerRule.value, numberRule.value, symbolRule.value].includes(SYSTEM_CONFIG_PASSWORD_TRUE)
      ? t("passwordRule.include")
      : "";
    if (includeMessage) {
      message += `\n${includeMessage}`;
    }

    message += [
      upperRule.value == SYSTEM_CONFIG_PASSWORD_TRUE ? t("passwordRule.oneUpperCase") : "",
      lowerRule.value == SYSTEM_CONFIG_PASSWORD_TRUE ? t("passwordRule.oneLowerCase") : "",
      numberRule.value == SYSTEM_CONFIG_PASSWORD_TRUE ? t("passwordRule.oneNumberCase") : "",
      symbolRule.value == SYSTEM_CONFIG_PASSWORD_TRUE ? t("passwordRule.oneSpecialCharacter") : "",
    ]
      .filter((str: string) => str.length)
      .join(", ");

    if (symbolRule.value == SYSTEM_CONFIG_PASSWORD_TRUE) {
      message += `\n${t("passwordRule.includeSpecialCharacter")}`;
    }

    return message;
  });

  return {
    minRule,
    SYSTEM_CONFIG_PASSWORD_MAXIMUM_LENGTH,
    SYSTEM_CONFIG_PASSWORD_TRUE,
    upperRule,
    lowerRule,
    numberRule,
    symbolRule,
    regexp,
    passwordRegexMessage,
  };
}
