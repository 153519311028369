import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    items: [] as any[],
    filter: {
      take: 20,
    },
  },
  getters: {
    getItems: (state: any) => state.items || [],
  },
  mutations: {
    SET_ITEMS: (state: any, payload: any) => (state.items = payload),
    ADD_ITEM: (state: any, payload: any) => {
      state.items.unshift(payload);
      state.items = state.items.filter((_: any, index: number) => index < 20);
    },
    SET_TAKE: (state: any, payload: any) => (state.filter.take = payload),
  },
  actions: {
    addRecievedSimData: async ({ commit }: ActionContext<any, any>, payload: any) => {
      // NOTE: request_params は 表示側で JSON.parse() されるので、JSON文字列にしておく
      payload.request_params = JSON.stringify(payload.request_params);
      commit("ADD_ITEM", payload);
    },
    setTake: ({ commit }: ActionContext<any, any>, payload: any) => commit("SET_TAKE", payload),
  },
};
