import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a880c06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "background" }
const _hoisted_2 = { class: "d-flex text-white align-center justify-center" }
const _hoisted_3 = {
  key: 0,
  class: "app---logo"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "font-weight-bold ml-3 logo-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.appLogo && _ctx.appLogo.base64)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.appLogo.base64,
                cover: "fill"
              }, null, 8, _hoisted_4)
            ]))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.background,
              alt: ""
            }, null, 8, _hoisted_5)),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.appName), 1)
      ]),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode("default form")
      ], true)
    ])
  ]))
}