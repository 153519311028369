type Lang = "ja" | "en";

export type State = {
  originalLanguage: Lang;
};

export default {
  state: {
    originalLanguage: undefined,
  },
  getters: {
    getOriginalLanguage: (state: State) => state.originalLanguage || undefined,
  },
  mutations: {
    SET_ORIGINAL_LANGUAGE: (state: State, lang: Lang) => (state.originalLanguage = lang),
  },
  actions: {
    setOriginalLanguage: (context: { commit: any; state: State }, lang: Lang) => {
      if (context.state.originalLanguage && context.state.originalLanguage !== lang) {
        return;
      }
      context.commit("SET_ORIGINAL_LANGUAGE", lang);
    },
  },
};
