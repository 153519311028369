import { ref } from "vue";
export default function useCheck() {
  const arrSelected = ref<number[]>([]);

  const resetSelection = () => {
    arrSelected.value = [];
  };

  return {
    arrSelected,
    resetSelection,
  };
}
