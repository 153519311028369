import { useI18n } from "vue-i18n";
import useConfig from "./useConfig";

export default function useSimStatus() {
  const { t } = useI18n();

  const statuses: any = {};

  const colors = {
    UNKNOWN: "#000000",
    TEST_READY_NAME: "#0E367D",
    INVENTORY_NAME: "#000000",
    TRIAL_NAME: "#000000",
    ACTIVATION_READY_NAME: "#0288D1",
    ACTIVATED_NAME: "#2E7D32",
    DEACTIVATED_NAME: "#D32F2F",
    RETIRED_NAME: "#000000",
    PURGED_NAME: "#D32F2F",
    ACTIVATED: "#2E7D32",
    PRE_ACTIVATED: "#ED6C02",
    SUSPENDED: "#D32F2F",
    OPERATOR_BARRED: "#000000",
    AVAILABLE: "#ED6C02",
    TERMINATED: "#D32F2F",
    TARIFF_HOLIDAY: "#000000",
  };

  const colorStyles = {
    INVENTORY_NAME: {
      textColor: "rgba(0, 0, 0, 0.8)",
      typeColor: "#46546D",
      bgColor: "rgba(0, 0, 0, 0.08)",
    },
    TRIAL_NAME: {
      textColor: "rgba(0, 0, 0, 0.8)",
      typeColor: "#BDBDBD",
      bgColor: "rgba(0, 0, 0, 0.08)",
    },
    ACTIVATION_READY_NAME: {
      textColor: "#FFFFFF",
      typeColor: "#0288D1",
      bgColor: "#2E7D32",
    },
    ACTIVATED_NAME: {
      textColor: "#FFFFFF",
      typeColor: "#2E7D32",
      bgColor: "#0288D1",
    },
    DEACTIVATED_NAME: {
      textColor: "#FFFFFF",
      typeColor: "#D32F2F",
      bgColor: "#D32F2F",
    },
    RETIRED_NAME: {
      textColor: "#FFFFFF",
      typeColor: "#46546D",
      bgColor: "#D32F2F",
    },
    PURGED_NAME: {
      textColor: "#FFFFFF",
      typeColor: "#C62828",
      bgColor: "#D32F2F",
    },
    ACTIVE: {
      textColor: "#FFFFFF",
      typeColor: "#2E7D32",
      bgColor: "#0288D1",
    },
    ACTIVATED: {
      textColor: "#FFFFFF",
      typeColor: "#01579B",
      bgColor: "#0288D1",
    },
    PRE_ACTIVATED: {
      textColor: "#FFFFFF",
      typeColor: "#ED6C02",
      bgColor: "#ED6C02",
    },
    SUSPENDED: {
      textColor: "#FFFFFF",
      typeColor: "#D32F2F",
      bgColor: "#D32F2F",
    },
    OPERATOR_BARRED: {
      textColor: "rgba(0, 0, 0, 0.8)",
      typeColor: "#BDBDBD",
      bgColor: "rgba(0, 0, 0, 0.08)",
    },
    TERMINATED: {
      textColor: "rgba(0, 0, 0, 0.8)",
      typeColor: "#46546D",
      bgColor: "rgba(0, 0, 0, 0.08)",
    },
    AVAILABLE: {
      textColor: "rgba(0, 0, 0, 0.8)",
      typeColor: "#46546D",
      bgColor: "rgba(0, 0, 0, 0.08)",
    },
    TARIFF_HOLIDAY: {
      textColor: "rgba(0, 0, 0, 0.8)",
      typeColor: "#46546D",
      bgColor: "rgba(0, 0, 0, 0.08)",
    },
    NTT_COM_ACTIVE: {
      typeColor: "#2E7D32",
    },
    NTT_COM_DEACTIVE: {
      typeColor: "#D32F2F",
    },
    UPDATED: {
      bgColor: "#ffa500",
    },
  };

  return {
    statuses,
    colors,
    colorStyles,
  };
}
