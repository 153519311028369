<template>
  <div class="background">
    <div>
      <div class="d-flex text-white align-center justify-center">
        <span v-if="appLogo && appLogo.base64" class="app---logo">
          <img :src="appLogo.base64" cover="fill" />
        </span>
        <img v-else :src="background" alt="" />
        <p class="font-weight-bold ml-3 logo-text">{{ appName }}</p>
      </div>
      <slot>default form</slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import bg from "@/assets/login-logo.svg";
import { useUserSetting } from "@/composables";

export default defineComponent({
  name: "AuthPageTemplate",
  setup() {
    const background = ref(bg);
    const { appLogo, appName } = useUserSetting();

    return {
      background,
      appLogo,
      appName,
    };
  },
});
</script>

<style scoped>
.background {
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  background-image: url("../../assets/background.png");
  background-size: 100% 100%;
}
.logo-text {
  font-size: 30px;
  letter-spacing: 0.02em;
}
</style>
