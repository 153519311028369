import { ActionContext } from "vuex";
import { ClassificationLogItemType } from "../../../server/demo-aicamera/src/common/types";

export default {
  namespaced: true,
  state: {
    cameraEvents: [] as Array<ClassificationLogItemType>,
    cameraEventCount: 0,
  },
  getters: {
    getEvent: (state: any): ClassificationLogItemType | undefined => state.cameraEvents.shift(), // remove first item
    getEvents: (state: any): ClassificationLogItemType[] => {
      const events = [];
      while (state.cameraEvents.length > 0) {
        events.unshift(state.cameraEvents.shift());
      }
      return events;
    },
    getEventCount: (state: any): number => {
      return state.cameraEventCount;
    },
  },
  mutations: {
    ADD_ITEM: (state: any, cameraEvent: ClassificationLogItemType) => {
      state.cameraEvents.unshift(cameraEvent);
      state.cameraEvents.splice(20, 1);
      state.cameraEventCount += 1;
    },
  },
  actions: {
    addCameraEvent: ({ commit }: ActionContext<any, any>, item: ClassificationLogItemType) => {
      commit("ADD_ITEM", item);
    },
  },
};
